import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { DashboardModule } from './features/dashboard/dashboard.module';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';

import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatDialogModule } from '@angular/material/dialog';
import { DialogBoardDeleteComponent } from './features/dialogs/dialog-board-delete.component';
import { DialogFilterSelectComponent } from './features/dialogs/dialog-filter-select.component';

import { DisplayMetricsSelectComponent } from './features/dialogs/display-metrics-select/display-metrics-select.component';

import { DialogMetaDataAddComponent } from './features/dialogs/dialog-metadata-add.component';
import { DialogBoardSaveComponent } from './features/dialogs/dialog-board-save.component';
import { DialogTabbedFilterComponent } from './features/dialogs/dialog-tabbed-filter.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/http-interceptors/token.interceptor';

import 'hammerjs';
import { EffectsModule } from '@ngrx/effects';

import { DialogBoardEditComponent } from './features/dialogs/dialog-board-edit.component';
import { DisplayAttributesSelectComponent } from './features/dialogs/display-attributes-select.component';
import { NgChartsModule } from 'ng2-charts';
import {
    DisplayAttributeMetricsSelectComponent
} from './features/dialogs/display-attribute-metrics-select/display-attribute-metrics-select.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MockDataService } from './core/data/mock-data.service';

registerLocaleData(en);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const imports: Array<any> = [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    ScrollingModule,

    // core & shared
    CoreModule,
    SharedModule,

    // app
    NgChartsModule,
    DashboardModule,
    AppRoutingModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    EffectsModule.forRoot([]),
    FormsModule,
    HttpClientModule,
    NgxDaterangepickerMd.forRoot()
];

if (environment.envName !== 'TEST') {
    Amplify.configure({
        aws_cognito_identity_pool_id: environment.awsCognitoIdentityPoolId,
        aws_user_pools_id: environment.awsUserPoolsId,
        aws_user_pools_web_client_id: environment.awsUserPoolsWebClientId
    });
    imports.push(AmplifyUIAngularModule);
} else {
    imports.push(HttpClientInMemoryWebApiModule.forRoot(MockDataService));
}

@NgModule({
    imports,
    entryComponents: [
        DialogBoardDeleteComponent,
        DialogFilterSelectComponent,
        DialogMetaDataAddComponent,
        DialogBoardSaveComponent,
        DialogBoardEditComponent,
        DisplayMetricsSelectComponent,
        DialogTabbedFilterComponent
    ],
    declarations: [
        AppComponent,
        DialogBoardDeleteComponent,
        DialogFilterSelectComponent,
        DialogMetaDataAddComponent,
        DialogBoardSaveComponent,
        DialogBoardEditComponent,
        DisplayMetricsSelectComponent,
        DialogTabbedFilterComponent,
        DisplayAttributesSelectComponent,
        DisplayAttributesSelectComponent,
        DisplayAttributeMetricsSelectComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: NZ_I18N, useValue: en_US }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
