<label [ngClass]="styling.wrapper()">
    <div [ngClass]="styling.label()">{{ label }}</div>

    <input
        *ngIf="this.items === ''"
        [ngClass]="styling.input()"
        (input)="onInput($event.target.value)"
        [disabled]="disabled"
        [value]="value"
        [name]="name"
    />

    <select
        *ngIf="this.items !== ''"
        [ngClass]="styling.input()"
        [disabled]="disabled"
        (change)="onInput($event.target.value)"
        [name]="name"
    >
        <option [ngClass]="styling.option()" *ngFor="let item of getOptions()" [value]="item" [selected]="value === item">
            {{ item }}
        </option>
    </select>

    <span class="icon" *ngIf="icon">
        <pt-ui-icon [variant]="icon" importance="secondary" size="l"></pt-ui-icon>
    </span>

    <span [ngClass]="styling.spinner()" *ngIf="spinning"></span>

    <span [ngClass]="styling.arrow()" *ngIf="this.items !== ''">
        <pt-ui-icon variant="down" size="l"></pt-ui-icon>
    </span>
</label>
