import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapsService } from '../../services/maps.service';
import { IBudgetCalculateTotalResponse } from '../../../../core/models/budget-calculate-total-response.model';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'lynkd-pattern-totals-table',
    templateUrl: './totals-table.component.html',
    styleUrls: ['./totals-table.component.scss']
})
export class TotalsTableComponent implements OnInit {
    @Input()
    public attributeValue: string;

    @Input()
    public timeValue: string;

    @Input()
    public set loading(data: boolean) {
        if (data) {
            this._loading = data;
            return;
        }

        this._loading = false;
    }

    public get loading(): boolean {
        return this._loading;
    }

    @Output()
    public readonly state: EventEmitter<IBudgetCalculateTotalResponse> = new EventEmitter();

    public data: IBudgetCalculateTotalResponse = {
        core_budget: 0,
        core_percentage: 0,
        fashion_budget: 0,
        fashion_percentage: 0,
        actual_budget: 0,
        actual_percentage: 0,
        total_budget: 0
    };

    public columns: Array<string> = ['total', 'core', 'fashion', 'actual'];

    public editColumn: string;

    private _loading: boolean;

    public constructor(private readonly _mapsService: MapsService) {}

    public ngOnInit(): void {
        this.loading = true;
        this._mapsService
            .getBudget(this.attributeValue, this.timeValue)
            .subscribe((res: IBudgetCalculateTotalResponse) => {
                if (res !== null) {
                    Object.assign(this.data, res);
                }

                this.updateState(this.data);
                this.loading = false;
            });
    }

    public startEdit(column: string): void {
        this.editColumn = column;
    }

    public async stopEdit(): Promise<void> {
        this.loading = true;

        try {
            const result: IBudgetCalculateTotalResponse = await firstValueFrom(
                this._mapsService.calculateBudgetTotals(
                    this.data.total_budget,
                    this.data.core_percentage,
                    this.data.fashion_percentage
                )
            );

            Object.assign(this.data, result);
            this.updateState(this.data);
            this.editColumn = null;
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }

    public getColumnValue(col: string, isPercentage?: boolean): number {
        if (col === 'total') {
            return this.data.total_budget;
        } else if (col === 'core') {
            if (isPercentage) {
                return this.data.core_percentage;
            } else {
                return this.data.core_budget;
            }
        } else if (col === 'fashion') {
            if (isPercentage) {
                return this.data.fashion_percentage;
            } else {
                return this.data.fashion_budget;
            }
        } else if (col === 'actual') {
            if (isPercentage) {
                return this.data.actual_percentage;
            } else {
                return this.data.actual_budget;
            }
        }
    }

    public updateState(newState: IBudgetCalculateTotalResponse): void {
        this.state.emit(newState);
    }
}
