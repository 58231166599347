import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data } from '@angular/router';
import { AppConfigService } from '../data/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    public constructor(
        private readonly _appConfigService: AppConfigService,
        private readonly _title: Title
    ) {
    }

    public setTitle(
        snapshot: ActivatedRouteSnapshot
    ): void {
        let lastChild: ActivatedRouteSnapshot = snapshot;
        while (lastChild.children.length) {
            lastChild = lastChild.children[0];
        }
        const { title }: Data = lastChild.data;
        if (title) {
            this._title.setTitle(this._appConfigService.appName);
        } else {
            this._title.setTitle(this._appConfigService.appName);
        }
    }
}
