import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../core/data/app-config.service';
import { ICurrentBoard } from '../../core/models/current-board.model';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
    private readonly apiUrl: string = this._appConfigService.apiUrl;
    private readonly baseUrl: string = '/api/boards';

    public constructor(private readonly _http: HttpClient,
                       private readonly _appConfigService: AppConfigService) {
    }
    public getPatternBoards(): Observable<Array<ICurrentBoard>> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}?userId=PATTERN_BOARDS`);

        return this._http.get<Array<ICurrentBoard>>(query);
    }

    public getSavedBoards(user_id: string): Observable<Array<ICurrentBoard>> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}?userId=${user_id}`);

        return this._http.get<Array<ICurrentBoard>>(query);
    }

    public saveBoard(data: ICurrentBoard): Observable<ICurrentBoard> {
        if (data.type !== 'PATTERN_BOARDS') {
            const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}`);
            if (data.board_id === 0) {
                return this._http.post<ICurrentBoard>(query, data);
            } else {
                return this._http.patch<ICurrentBoard>(`${query}/${data.board_id}`, data);
            }
        }
        return null;
    }

    public deleteBoard(board_id: string): Observable<string> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}/${board_id}`);

        return this._http.delete<string>(query);
    }
}
