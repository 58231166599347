<div class="row no-gutters attribute-row" *ngIf="attributeRows">
    <div class="col-12 attribute-table-column">
        <lynkd-pattern-attribute-table
            [attribute]="attribute.display_name"
            [attributeColumn]="attribute.column_name"
            [attributeRows]="attributeRows"
        ></lynkd-pattern-attribute-table>
    </div>
</div>
<ng-template #loading>
    <div class="row attribute-row">
        <div class="col-sm-1 mx-auto">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</ng-template>
