import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { defer, isObservable, Observable, of } from 'rxjs';
import { first, mergeMap, shareReplay } from 'rxjs/operators';
import { IApplicationMenuItemModel } from '../models/application-menu-item.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApplicationMenuService {
    public apiUrl: string = environment.apiUrl;
    public baseUrl: string = '/api/application';
    // Todo: Cache array in memory instead
    private returnObs$: Observable<Array<IApplicationMenuItemModel>>;
    private menuItems$: Observable<Array<IApplicationMenuItemModel>>;

    public constructor(private readonly _http: HttpClient) {}

    public getAll(): Observable<Array<IApplicationMenuItemModel>> {
        if (!this.menuItems$) {
            this.menuItems$ = this.renewAfterTimer(
                this._http.get<Array<IApplicationMenuItemModel>>(`${this.apiUrl + this.baseUrl}`),
                1000 * 60 * 60 * 24 * 7
            );
        }
        return this.menuItems$;
    }

    private createReturnObs(
        obs: Observable<Array<IApplicationMenuItemModel>>,
        time: number,
        bufferReplays: number
    ): Observable<Array<IApplicationMenuItemModel>> {
        return (this.returnObs$ = obs.pipe(shareReplay(bufferReplays, time)));
    }

    private renewAfterTimer(
        obs: Observable<Array<IApplicationMenuItemModel>>,
        time: number,
        bufferReplays: number = 1
    ): Observable<Array<IApplicationMenuItemModel>> {
        return this.createReturnObs(obs, time, bufferReplays).pipe(
            first(
                null,
                defer(() => this.createReturnObs(obs, time, bufferReplays))
            ),
            mergeMap((d: Observable<Array<IApplicationMenuItemModel>> | Array<IApplicationMenuItemModel>) =>
                isObservable(d) ? d : of(d)
            )
        );
    }
}
