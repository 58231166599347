import { Pipe, PipeTransform } from '@angular/core';
/*
 * Convert Meta Data attribute name to formatted name
 * Usage:
 *   name | displayName
 * Example:
 *   {{ meta_data.name | displayName }}
 *   attr_plan_seaon formats to: Plan Season
 */
@Pipe({
    name: 'displayName'
})
export class FormatDisplayNamePipe implements PipeTransform {

    public transform(name: string): string {
        try {
            return name.replace('attr', '').replace(/_/g, ' ').trim();
        } catch (e) {
            return name;
        }
    }
}
