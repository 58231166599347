import { Component, Input, ViewChild } from '@angular/core';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'lynkd-pattern-attribute-bar-graph',
    templateUrl: './attribute-bar-graph.component.html',
    styleUrls: ['./attribute-bar-graph.component.scss']
})
export class AttributeBarGraphComponent {
    public get attributeRows(): Array<IProductAttributeRow> {
        return this._attributeRows;
    }

    @Input()
    public set attributeRows(value: Array<IProductAttributeRow>) {
        this._attributeRows = value;
        this.mapDatasets();
    }

    public get attribute(): string {
        return this._attribute;
    }

    @Input()
    public set attribute(value: string) {
        this._attribute = value;
    }

    @ViewChild(BaseChartDirective)
    public chart?: BaseChartDirective;
    public chartData: ChartConfiguration<'bar'>['data'] = {
        labels: ['Black', 'White', 'Navy', 'Red', 'Green'],
        datasets: [
            {
                label: 'TY AWC %',
                backgroundColor: '#efb758',
                borderColor: '#efb758',
                data: [8, 16, 12, 10, 54],
                stack: 'AWC'
            },
            {
                label: 'TY SLS U/%',
                backgroundColor: '#7cb5ec',
                borderColor: '#000000',
                data: [300, 400, 500, 600, 700],
                stack: 'Units'
            },
            {
                label: 'LY AWC %',
                backgroundColor: '#fff1c0',
                borderColor: '#fff1c0',
                data: [7, 15, 11, 9, 53],
                stack: 'AWC'
            },
            {
                label: 'LY SLS U/%',
                backgroundColor: '#434348',
                borderColor: '#000000',
                data: [20, 30, 60, 30, 50],
                stack: 'Units'
            }
        ]
    };
    // noinspection JSUnusedGlobalSymbols
    public chartOptions: ChartOptions<'bar'> = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true
                }
            }
        },
        interaction: {
            intersect: false
        },
        scales: {
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 10
                    }
                },
                afterFit: (scaleInstance?: { width: number }) => {
                    scaleInstance.width = 100;
                },
                position: 'left'
            },
            y1: {
                stacked: true,
                position: 'right',
                ticks: {
                    font: {
                        size: 10
                    },
                    callback: (value: string | number) => value + ' %'
                },
                afterFit: (scaleInstance?: { width: number }) => {
                    scaleInstance.width = 100;
                }
            }
        }
    };
    public chartLegend: boolean = true;
    private _attribute: string;
    private _attributeRows: Array<IProductAttributeRow> = [];

    private mapDatasets(): void {
        if (!this._attributeRows) {
            return;
        }
        this.chartData.labels = this._attributeRows.map((t: IProductAttributeRow) =>
                t.attribute_value.substring(0, 19)
            // return t.attribute_value.substring(0, 1);
        );
        const tyAwcPerc: Array<number> = this._attributeRows.map((t: IProductAttributeRow) => Math.max(t.awc_ty, 0));
        const lyAwcPerc: Array<number> = this._attributeRows.map((t: IProductAttributeRow) => Math.max(t.awc_ly, 0));
        const tySlsPerc: Array<number> = this._attributeRows.map((t: IProductAttributeRow) => Math.max(t.sls_u_ty, 0));
        const lySlsPerc: Array<number> = this._attributeRows.map((t: IProductAttributeRow) => Math.max(t.sls_u_ly, 0));
        this.chartData.datasets = [
            {
                label: 'TY AWC %',
                backgroundColor: '#efb758',
                borderColor: '#efb758',
                pointBackgroundColor: '#efb758',
                pointBorderColor: '#efb758',
                pointHoverBackgroundColor: '#efb758',
                pointHoverBorderColor: '#efb758',
                data: tyAwcPerc,
                stack: 'AWC',
                type: 'line',
                yAxisID: 'y1'
            },
            {
                label: 'TY SLS U/%',
                backgroundColor: '#3a668c',
                borderColor: '#3a668c',
                data: tySlsPerc,
                stack: 'Units',
                barThickness: 10,
                yAxisID: 'y'
            },
            {
                label: 'LY AWC %',
                backgroundColor: '#fff1c0',
                borderColor: '#fff1c0',
                pointBackgroundColor: '#fff1c0',
                pointBorderColor: '#fff1c0',
                pointHoverBackgroundColor: '#fff1c0',
                pointHoverBorderColor: '#fff1c0',
                data: lyAwcPerc,
                stack: 'AWC',
                barThickness: 10,
                yAxisID: 'y1'
            },
            {
                label: 'LY SLS U/%',
                backgroundColor: '#90adc6',
                borderColor: '#90adc6',
                data: lySlsPerc,
                stack: 'Units',
                barThickness: 10,
                type: 'line',
                yAxisID: 'y'
            }
            // The config type is weird
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as any;

        this.chart?.update();
    }
}
