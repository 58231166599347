import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { IProductPerformance } from '../models/product-performance.model';
import { AppConfigService } from './app-config.service';
import * as moment from 'moment';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { IProduct } from '../models/product.model';
import { ILastUpdate } from '../models/last-update.model';
import { IMetricRange } from '../models/metric-range.model';
import { IDisplayMetric } from '../models/display-metric.model';
import { ITimePeriod } from '../models/time-period.model';
import { IProductMetas } from '../models/product-metas.model';
import { map} from 'rxjs/operators';
import { IProductOrderModel } from '../models/product-order.model';
import { IProductKeyMetricModel } from '../models/product-key-metric.model';
import { IImage } from '../models/image.model';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    public apiUrl: string = this._appConfigService.apiUrl;
    public apiLastUpdate: string = '/api/timemeta/lastUpdate';
    public apiTime: string = '/api/timemeta';
    public apiLocation: string = '/api/LocationHierarchy';
    public apiProducts: string = '/api/products';
    public apiProductImages: string = '/api/products/images';
    public apiProductMeta: string = '/api/productmeta/all';
    // apiSub = '/api/producthierarchy/subdepartment_name';
    // apiProductSortOrder = '/api/products/sortcolumns';
    public apiProductMetrics: string = '/api/products/displaymetrics';
    public apiProductOrders: string = '/api/orders';
    public apiProductPerformanceItems: string = '/api/productperformance/charts';
    // apiProductPerformance = '/api/productperformance';
    public apiProductKeyMetrics: string = '/api/productperformance/cardmetrics';
    // public boardType: Subject<DisplayModelLabel> = new Subject<DisplayModelLabel>();
    public metricAlignment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public headers: { headers: HttpHeaders } = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
        })
    };

    public constructor(
        private readonly _http: HttpClient,
        private readonly _appConfigService: AppConfigService,
        private readonly _localStorageService: LocalStorageService
    ) {}

    // public getBoardType(data: DisplayModelLabel): void {
    //     this.boardType.next(data);
    // }

    public metricAlignmentChange(data: boolean): void {
        this.metricAlignment.next(data);
    }


    public async getProducts(
        ids: string,
        time_period: string,
        time_range: string | Array<string>,
        department: Array<string>,
        sub_department: Array<string>,
        category: Array<string>,
        product_type: Array<string>,
        metric_range: Array<IMetricRange>,
        fieldsSet: string,
        sortOrder: string | Array<string>,
        pageSize: number,
        currentPage: number,
        meta: Record<string, Array<string>>,
        locationCompanies: Array<string>,
        locationDivisions: Array<string>,
        locationAreas: Array<string>,
        locationRegions: Array<string>,
        locationStores: Array<string>
    ): Promise<Array<IProduct>> {
        let time: string | Array<string>;
        if (time_range.length) {
            time = (time_range as string).split(' - ');
            const startDate: string = time[0];
            const endDate: string = time[1];
            time = `start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            time = `${time_period}`;
        }

        const departmentName: Array<string> = department ? department.map((value: string) => `department_name:'${value}'`) : [];
        const subDepartmentName: Array<string> = sub_department
            ? sub_department.map((value: string) => `sub_department_name:'${value}'`)
            : [];
        const categoryName: Array<string> = category ? category.map((value: string) => `category_name:'${value}'`) : [];
        const productTypeName: Array<string> = product_type
            ? product_type.map((value: string) => `product_type_name:'${value}'`)
            : [];

        const hierarchyFilters: string = `${departmentName
            .concat(subDepartmentName)
            .concat(categoryName)
            .concat(productTypeName)
            .join(',')}`;

        const activeMetricRanges: Array<string> = metric_range.map(
            (value: IMetricRange) => `${value.metric_name}:${value.values[0]}:${value.values[1]}`
        );

        const metricRangeFilters: string = `${activeMetricRanges.join(',')}`;

        const locationFilters: Array<string> = [];

        if (locationCompanies.length) {
            locationCompanies.map((location: string) => {
                locationFilters.push(`fin_co_name:'${location}'`);
            });
        }
        if (locationDivisions.length) {
            locationDivisions.map((location: string) => {
                locationFilters.push(`division_descr:'${location}'`);
            });
        }
        if (locationAreas.length) {
            locationAreas.map((location: string) => {
                locationFilters.push(`trademark_descr:'${location}'`);
            });
        }
        if (locationRegions.length) {
            locationRegions.map((location: string) => {
                locationFilters.push(`region_descr:'${location}'`);
            });
        }
        if (locationStores.length) {
            locationStores.map((location: string) => {
                locationFilters.push(`store_name:'${location}'`);
            });
        }

        const metaData: Array<string> = [];
        if (meta) {
            for (const property in meta) {
                if (meta[property]) {
                    for (const value of meta[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }
        // const keys = Object.keys(meta);
        // for (let i = 0; i < keys.length; ++i) {
        //     const key = keys[i];
        //     meta_data.push(meta[key].map((value: string) => `${key}:'${value}'`));
        // }
        // for (const attr in meta) {
        //     if (meta.hasOwnProperty(attr)) {
        //         meta_data.push(meta[attr].map((value: string) => `${attr}:'${value}'`));
        //     }
        // }
        const params: HttpParams = new HttpParams()
            .set('ids', ids)
            .set('hierarchy_filters', hierarchyFilters)
            .set('metric_range_filters', metricRangeFilters)
            .set('location_filters', locationFilters.join(','))
            .set('meta_filters', metaData.join(','))
            .set('time_period', time)
            .set('fields', fieldsSet)
            .set('sort_order', sortOrder as string)
            .set('page_size', pageSize)
            .set('page_number', currentPage.toString());
        // .set('page_size', pageSize.toString());
        const cachedQuery: string = this._localStorageService.getItem<string>('products-query-cache');
        if (cachedQuery === params.toString()) {
            return this._localStorageService.getItem<Array<IProduct>>('products-row-cache');
        }
        const productCache: Array<IProduct> = await firstValueFrom(
            this._http.get<Array<IProduct>>(this.apiUrl + this.apiProducts, {
                // headers: this.headers.headers,
                params
            })
        );
        this._localStorageService.setItem<Array<IProduct>>('products-row-cache', productCache);
        this._localStorageService.setItem<string>('products-query-cache', params.toString());
        return productCache;
    }

    public getProductImages(product_id: string): Observable<Array<IImage>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductImages}/${product_id}`);

        return this._http.get<Array<IImage>>(query, this.headers);
    }

    public getTimePeriods(): Observable<Array<ITimePeriod>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/periods`);

        return this._http.get<Array<ITimePeriod>>(query, this.headers);
    }

    public getTimeYears(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/hierarchy/yearname`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getTimeQuarters(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/hierarchy/quartername`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getTimeMonths(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/hierarchy/monthname`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationCompany(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/fin_co_name`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationDivision(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/division_descr`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationArea(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/trademark_descr`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationRegion(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/region_descr`);
        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationStore(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/store_name`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getProductMetas(): Observable<Array<IProductMetas>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductMeta}`);
        return this._http
            .get<Array<IProductMetas>>(query, this.headers)
            .pipe(map((t: Array<IProductMetas>) => t.filter((x: IProductMetas) => x.attribute !== 'active_job')));
    }

    public getProductSortOrders(): Observable<Array<IDisplayMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductMetrics}`);

        return this._http.get<Array<IDisplayMetric>>(query, this.headers);
    }

    public getProductMetrics(): Observable<Array<IDisplayMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductMetrics}`);

        return this._http.get<Array<IDisplayMetric>>(query, this.headers);
    }

    public getProductKeyMetrics(
        product_id: string,
        time_period: string | Array<string>,
        time_range: string | Array<string>,
        locationCompanies: string | Array<string>,
        locationDivisions: string | Array<string>,
        locationAreas: string | Array<string>,
        locationRegions: string | Array<string>,
        locationStores: string | Array<string>
    ): Observable<Array<IProductKeyMetricModel>> {
        let time: string | Array<string>;

        if (time_range.length) {
            time = (time_range as string).split(' - ');
            const startDate: string = time[0];
            const endDate: string = time[1];
            time = `?time_range=start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            time = `?time_period=${time_period}`;
        }

        const locationFilters: Array<string> = [];
        if (locationCompanies.length) {
            (locationCompanies as Array<string>).map((location: string) => {
                locationFilters.push(`fin_co_name:'${location}'`);
            });
        }
        if (locationDivisions.length) {
            (locationDivisions as Array<string>).map((location: string) => {
                locationFilters.push(`division_descr:'${location}'`);
            });
        }
        if (locationAreas.length) {
            (locationAreas as Array<string>).map((location: string) => {
                locationFilters.push(`trademark_descr:'${location}'`);
            });
        }
        if (locationRegions.length) {
            (locationRegions as Array<string>).map((location: string) => {
                locationFilters.push(`region_descr:'${location}'`);
            });
        }
        if (locationStores.length) {
            (locationStores as Array<string>).map((location: string) => {
                locationFilters.push(`store_name:'${location}'`);
            });
        }

        const query: string = encodeURI(
            `${this.apiUrl}${this.apiProductKeyMetrics}/${product_id}${time}${
                locationFilters ? `&location_filters=${locationFilters.join(',')}` : ''
            }`
        );

        return this._http.get<Array<IProductKeyMetricModel>>(query, this.headers);
    }

    public getProductOrders(product_id: string): Observable<Array<IProductOrderModel>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductOrders}/${product_id}`);

        return this._http.get<Array<IProductOrderModel>>(query, this.headers);
    }

    public getProductPerformanceItems(): Observable<string> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductPerformanceItems}`);

        return this._http.get<string>(query, this.headers);
    }

    public getProductPerformance(api: string, product_id: string): Observable<Array<IProductPerformance>> {
        const query: string = encodeURI(`${this.apiUrl}${api}${product_id}`);

        return this._http.get<Array<IProductPerformance>>(query, this.headers);
    }

    public getLastUpdate(): Observable<Array<ILastUpdate>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLastUpdate}`);

        return this._http.get<Array<{ last_update: string }>>(query);
    }
}
