<figure [style.height.px]='itemHeight'>
    <img
        *ngIf='product?.image_id'
        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
        [alt]='product?.image_id'
        [style.backgroundImage]="'url(' + encoder.encodeValue(product?.image_id?.trim()?.replaceAll('%20',' ')) + ')'"
    />
    <div class='exclude-container' [hidden]='product?.style_id !== hovered'>
        <button class='exclude-btn' (click)='onClearHandler($event)'>
            <img src='assets/icon-circle-clear-alt.svg' alt='' width='10px' height='auto' class='exclude' />
        </button>
    </div>


    <div class='comment-container' *ngIf='hasComments'>
        <fa-icon icon='comment'></fa-icon>
    </div>
    <ul class='tags'>
        <li *ngFor='let tag of product?.tags'>{{ tag }}</li>
    </ul>

    <ng-content></ng-content>
</figure>
