import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ListingDisplayMode } from "../../../core/models/display-mode.enum";
import { IInputs } from "../../../core/models/inputs.model";
import { IFilters } from "../../../core/models/filters.model";
import { DialogFilterTypes } from "../../../core/models/dialog-filter-type.enum";
import { DialogDisplayMode } from "../../../core/models/dialog-display-mode.enum";
import { Observable } from "rxjs";
import { IFilterData } from "../../../core/models/filter-data.model";
import { IDisplayMetric } from "../../../core/models/display-metric.model";
import { IProductDisplayAttributeMetric } from "../../../core/models/product-display-attribute-metric.model";
import { IProductDisplayAttribute } from "../../../core/models/product-display-attribute.model";

@Component({
    selector: "lynkd-pattern-application-navigation-bar",
    templateUrl: "./application-navigation-bar.component.html",
    styleUrls: ["./application-navigation-bar.component.scss"]
})
export class ApplicationNavigationBarComponent {
    @Input()
    public gallery: number;
    @Input()
    public isAuthenticated$: Observable<boolean>;
    @Input()
    public toolbarOpenState: boolean;
    @Input()
    public filterData: IFilterData;
    @Input()
    public sortToggleAscending: boolean;
    @Input()
    public inputs: IInputs;
    @Input()
    public filters: IFilters;
    @Input()
    public attributeDisplayMetrics: Array<IProductDisplayAttributeMetric>;
    @Output()
    public readonly openDisplayAttributeClicked: EventEmitter<{
        items: Array<IProductDisplayAttribute>,
        selectedItem: Array<IProductDisplayAttribute>,
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }> = new EventEmitter<{
        items: Array<IProductDisplayAttribute>;
        selectedItem: Array<IProductDisplayAttribute>;
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }>();
    @Output()
    public readonly openSortClicked: EventEmitter<{
        items: Array<IDisplayMetric>,
        selectedItem: Array<string>,
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }> = new EventEmitter<{
        items: Array<IDisplayMetric>;
        selectedItem: Array<string>;
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }>();
    @Output()
    public readonly openDisplayMetricsClicked: EventEmitter<{
        items: Array<IDisplayMetric>,
        selectedItem: Array<IDisplayMetric>,
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }> = new EventEmitter<{
        items: Array<IDisplayMetric>;
        selectedItem: Array<IDisplayMetric>;
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }>();
    @Output()
    public readonly openAttributeDisplayMetricsClicked: EventEmitter<{
        items: Array<IProductDisplayAttributeMetric>,
        selectedItem: Array<IProductDisplayAttributeMetric>,
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }> = new EventEmitter<{
        items: Array<IProductDisplayAttributeMetric>;
        selectedItem: Array<IProductDisplayAttributeMetric>;
        input: DialogFilterTypes;
        subInput: string;
        gallery: ListingDisplayMode;
        display_mode: DialogDisplayMode;
        listing_display_mode: string;
    }>();
    @Output()
    public readonly sortToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public readonly searchChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public readonly clearClicked: EventEmitter<void> = new EventEmitter<void>();

    // eslint-disable-next-line @typescript-eslint/typedef
    public ListingDisplayMode = ListingDisplayMode;

    // eslint-disable-next-line @typescript-eslint/typedef
    public DialogFilterTypes = DialogFilterTypes;

    // eslint-disable-next-line @typescript-eslint/typedef
    public DialogDisplayMode = DialogDisplayMode;

    public toggleSort(): void {
        this.sortToggled.emit();
    }

    public openDisplayAttributes(
        items: Array<IProductDisplayAttribute>,
        selectedItem: Array<IProductDisplayAttribute>,
        input: DialogFilterTypes,
        gallery: ListingDisplayMode,
        displayMode: DialogDisplayMode
    ): void {
        this.openDisplayAttributeClicked.emit({
            items,
            selectedItem,
            input,
            subInput: null,
            gallery,
            display_mode: displayMode,
            listing_display_mode: null
        });
    }

    public openDisplayMetrics(
        items: Array<IDisplayMetric>,
        selectedItem: Array<IDisplayMetric>,
        input: DialogFilterTypes,
        gallery: ListingDisplayMode,
        displayMode: DialogDisplayMode
    ): void {
        this.openDisplayMetricsClicked.emit({
            items,
            selectedItem,
            input,
            subInput: null,
            gallery,
            display_mode: displayMode,
            listing_display_mode: null
        });
    }

    public openAttributeDisplayMetrics(
        items: Array<IProductDisplayAttributeMetric>,
        selectedItem: Array<IProductDisplayAttributeMetric>,
        input: DialogFilterTypes,
        gallery: ListingDisplayMode,
        displayMode: DialogDisplayMode
    ): void {
        this.openAttributeDisplayMetricsClicked.emit({
            items,
            selectedItem,
            input,
            subInput: null,
            gallery,
            display_mode: displayMode,
            listing_display_mode: null
        });
    }

    public openSort(
        items: Array<IDisplayMetric>,
        selectedItem: Array<string>,
        input: DialogFilterTypes.SortOrder,
        displayMode: DialogDisplayMode,
        listingDisplayMode: string
    ): void {
        this.openSortClicked.emit({
            items,
            selectedItem,
            input,
            subInput: null,
            gallery: null,
            display_mode: displayMode,
            listing_display_mode: listingDisplayMode
        });
    }

    public clear(): void {
        this.clearClicked.emit();
    }

    public search(): void {
        this.searchChanged.emit(this.inputs.search);
    }
}
