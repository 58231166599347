import { Component } from '@angular/core';
import { LevelItemComponent } from '../level-item';
import { PlanBlockComponent } from '../plan-block';
import { SectionHeaderComponent } from '../section-header';

@Component({
    standalone: true,
    selector: 'pt-ui-screen-sidebar',
    templateUrl: './screen-sidebar.component.html',
    styleUrls: ['./screen-sidebar.component.scss'],
    imports: [SectionHeaderComponent, PlanBlockComponent, LevelItemComponent]
})
export class ScreenSidebarComponent {}
