import * as schema from '../schema';

/**
 *
 */
const formatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short'
});

/**
 *
 */
export const toZAR = (value: number) => formatter.format(value);

/**
 *
 */
export const calcIfLevelItemSelectable = (props: Pick<schema.interfaces.LevelItem, 'selected' | 'open'>) => {
    const { open, selected } = props;
    if (typeof selected === 'boolean') return true;
    if (typeof open === 'boolean') return true;
    return false;
};

/**
 *
 */
export const calcPlanBlockTotals = (props: Pick<schema.interfaces.PlanBlock, 'usage'>) => {
    const { usage } = props;
    const { core, season } = usage;

    const value = core.value + season.value;
    const max = core.max + season.max;
    const percentage = (value / max) * 100;

    return {
        value,
        max,
        percentage
    };
};
