import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'pt-ui-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    imports: [CommonModule]
})
export class ChipComponent {
    @Input() public status: 'active' | 'selectable' | 'disabled' = 'selectable';
    @Input() public label: string = '';

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--active': this.status === 'active',
            'wrapper--disabled': this.status === 'disabled'
        })
    };

    public content = {
        icon: () => this.status === 'active'
    };
}
