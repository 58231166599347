/* tslint:disable:no-any */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Gallery, GalleryRef } from '@ngx-gallery/core';
import { MatTableDataSource } from '@angular/material/table';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IMAGEVIEWER_CONFIG, ImageViewerConfig } from '@devadri/ngx-imageviewer';

import { DataService } from '../../core/data/data.service';

import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { ExcelExportService } from '../../core/data/excel-export.service';
import { MatSort } from '@angular/material/sort';
import { IProductPerformance } from '../../core/models/product-performance.model';
import { IProduct } from '../../core/models/product.model';
import {
    BarElement,
    Chart,
    ChartConfiguration,
    ChartType,
    BarController,
    CategoryScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { IProductDetailsConfigModel } from '../../shared/models/product-details-config.model';
import { ProductDetailsConfigService } from '../../shared/services/product-details-config.service';
import { StyleCommentService } from '../../shared/services/style-comment.service';
import { IImage } from '../../core/models/image.model';
import { IProductOrderModel } from '../../core/models/product-order.model';
import { IProductComment } from '../../core/models/product-comment.model';
// import() returns a Promise
// const importChart = normalizeCommonJSImport(import(/* webpackChunkName: "chart" */ 'chart.js'));

const imageViewerConfig: ImageViewerConfig = {
    bgStyle: '#ffffff',
    tooltips: {
        enabled: false,
        bgStyle: 'transparent',
        padding: 5
    },
    buttonStyle: {
        bgStyle: '#efb758',
        iconStyle: '#666666',
        hoverAlpha: 100
    },
    zoomInButton: {
        tooltip: ''
    },
    zoomOutButton: {
        tooltip: ''
    },
    nextPageButton: {
        show: false
    },
    beforePageButton: {
        show: false
    },
    rotateLeftButton: {
        show: false
    },
    rotateRightButton: {
        show: false
    },
    resetButton: {
        show: false
    }
};

@Component({
    selector: 'lynkd-pattern-dialog-product-view',
    templateUrl: './dialog-product-view.component.html',
    styleUrls: ['./dialog-product-view.component.scss'],
    providers: [
        {
            provide: IMAGEVIEWER_CONFIG,
            useValue: imageViewerConfig
        }
    ]
})
export class DialogProductViewComponent implements OnInit {
    @ViewChild(MatSort)
    public sort: MatSort;

    public originalData: {
        products: Array<IProduct>;
        product_id: string;
        user_id: string;
        time_period: string;
        time_range: string | Array<string>;
        location_company: Array<string>;
        location_division: Array<string>;
        location_area: Array<string>;
        location_region: Array<string>;
        location_store: Array<string>;
    };
    // public productsAll: any;
    public products: Array<IProduct> = [];
    public product: IProduct;
    public currentProductIndex: number;

    public inputs: {
        comment: string;
        performance_detail: string;
        order: string;
    } = {
        order: '',
        performance_detail: '',
        comment: ''
    };

    public timePeriod: string;
    public timeRange: string | Array<string>;

    // public commentsLength: string;
    public commentsShow: boolean = false;

    public productLoading: boolean = false;
    public productKeyMetricsLoading: boolean = false;
    public productOrdersLoading: boolean = false;
    public commentsLoading: boolean = false;
    public productPerformanceLoading: boolean = false;

    // public images: Array<GalleryItem> = [];
    public imagesLoading: boolean = false;
    public galleryId: string = 'productGallery';
    public imgWidth: number;
    public imgHeight: number;

    // public productKeyMetrics: any;
    public keyMetricsAll: boolean = false;
    public isShowingTable: boolean = false;

    // public tableRows: Array<any> = [];
    public tableColumns: Array<{
        name: string;
    }> = [];

    public productStyleColumns: Array<IProductDetailsConfigModel> = [];
    // productOrderColumns: string[] = [];
    // productOrderData: any;
    public productOrderDataSelected: Array<IProductOrderModel> = [];

    // productPerformanceItems: any;
    // productPerformanceData: any;
    public productPerformanceDataSelected: Array<{
        chart_name: string;
        chart_type: string;
        data_service?: string;
        sort_order?: number;
    }> = [
        {
            chart_name: '',
            chart_type: 'Chart'
        }
    ];

    // productPerformanceColumns: string[] = [];

    // public chart: any;
    // @ViewChild('lineChart') public chartElement: ElementRef<HTMLCanvasElement>;
    @ViewChild(BaseChartDirective)
    public chart?: BaseChartDirective;
    public chartData: ChartConfiguration['data'];
    public chartOptions: ChartConfiguration['options'];
    public chartLegend: boolean = true;
    public chartType: ChartType = 'line';
    public dataSource: MatTableDataSource<IProductPerformance> = new MatTableDataSource();
    private triggerCommentIndicatorOnClose: boolean = false;

    // public ImageViewerConfig = {
    //     containerBackgroundColor: '#ffffff'
    // };
    public constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            products: Array<IProduct>;
            product_id: string;
            user_id: string;
            time_period: string;
            time_range: string | Array<string>;
            location_company: Array<string>;
            location_division: Array<string>;
            location_area: Array<string>;
            location_region: Array<string>;
            location_store: Array<string>;
        },
        private readonly _gallery: Gallery,
        private readonly _dataService: DataService,
        private readonly _excelExportService: ExcelExportService,
        private readonly _dialogRef: MatDialogRef<DialogProductViewComponent>,
        private readonly _productDetailsConfigService: ProductDetailsConfigService,
        private readonly _commentService: StyleCommentService
    ) {
        Chart.register(BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip, ChartDataLabels);
        this.originalData = data;
        this.products = data.products;
        this.currentProductIndex = this.products.indexOf(
            this.products.filter((product: IProduct) => product.style_id === data.product_id)[0]
        );
    }

    public onChange(): void {
        localStorage.setItem('inputs.performance_detail', JSON.stringify(this.inputs.performance_detail));
    }

    public async ngOnInit(): Promise<void> {
        const localStorageInputsPerformanceDetail: string = 'style_vs_category_clr';

        localStorage.setItem('inputs.performance_detail', JSON.stringify(localStorageInputsPerformanceDetail));

        const windowWidth: number = window.innerWidth;
        const windowHeight: number = window.innerHeight;

        this.imgWidth = Math.floor(windowWidth / 3 - 40);
        this.imgHeight = Math.floor(windowHeight / 1.33 - 40 - 60 - 74);
        this.inputs = {
            order: '',
            performance_detail: '',
            comment: ''
        };
        this.productStyleColumns = await this._productDetailsConfigService.getAll();
        // this.productStyleColumns = [
        //     'style_id',
        //     'supplier_name',
        //     'colour_name',
        //     /* 'supplier_style_reference', */
        //     'brand_name',
        //     'allocation_type',
        //     'department_name',
        //     'sub_department_name',
        //     'category_name',
        //     'product_type_name'
        // ];

        await this.loadProductData(this.currentProductIndex);

        this._dialogRef.beforeClosed().subscribe(async () => {
            if (this.triggerCommentIndicatorOnClose) {
                await this._commentService.refreshHasComments();
            }
        });
    }

    // Drag and drop tbale columns
    public drop(event: CdkDragDrop<Array<IProductPerformance>, Array<IProductPerformance>>): void {
        moveItemInArray(
            this.products[this.currentProductIndex].productPerformanceColumns,
            event.previousIndex,
            event.currentIndex
        );
    }

    // public ngOnDestroy() {
    //     // this.products = [];
    //     // this.productsAll = [];
    // }

    public async loadProductData(productIndex: number): Promise<void> {
        this.currentProductIndex = productIndex;

        this.inputs.performance_detail = JSON.parse(localStorage.getItem('inputs.performance_detail'));
        const exampleChart: string = this.inputs.performance_detail;

        try {
            if (!this.products[productIndex].images) {
                await this.getProductData(productIndex);
            }
        } catch (e) {
            // Todo: swallowed exception
        }

        try {
            if (this.products[this.currentProductIndex].productPerformanceItems) {
                this.inputs.performance_detail = `${exampleChart}`;
                await this.onProductPerformanceItemSelectChange(this.currentProductIndex);
            }
        } catch (e) {
            // Todo: swallowed exception
        }
    }

    public async getProductData(productIndex: number): Promise<void> {
        this.timePeriod = this.originalData.time_period;
        this.timeRange = this.originalData.time_range;
        const locationCompany: Array<string> = this.originalData.location_company;
        const locationDivision: Array<string> = this.originalData.location_division;
        const locationArea: Array<string> = this.originalData.location_area;
        const locationRegion: Array<string> = this.originalData.location_region;
        const locationStore: Array<string> = this.originalData.location_store;
        let localProductIndex: number;
        if (this.currentProductIndex === productIndex) {
            this.productLoading = true;
        }

        try {
            const productWithStyleColumns: IProduct = (await this._dataService
                .getProducts(
                    this.products[productIndex].style_id,
                    this.timePeriod,
                    this.timeRange,
                    [],
                    [],
                    [],
                    [],
                    [],
                    this.productStyleColumns.map((t: IProductDetailsConfigModel) => t.columnName).join(','),
                    '',
                    1,
                    1,
                    {},
                    locationCompany,
                    locationDivision,
                    locationArea,
                    locationRegion,
                    locationStore
                ))[0];
            const response: IProduct = (await this._dataService
                .getProducts(
                    this.products[productIndex].style_id,
                    this.timePeriod,
                    this.timeRange,
                    [],
                    [],
                    [],
                    [],
                    [],
                    '',
                    '',
                    1,
                    1,
                    {},
                    locationCompany,
                    locationDivision,
                    locationArea,
                    locationRegion,
                    locationStore
                ))[0];
            for (const productStyleColumn of this.productStyleColumns) {
                response[productStyleColumn.columnName] = productWithStyleColumns[productStyleColumn.columnName];
            }
            localProductIndex = this.products.indexOf(
                this.products.filter((product: IProduct) => product.style_id === response.style_id)[0]
            );

            const imageId: string = this.products[localProductIndex].image_id;
            this.products[localProductIndex] = response;
            this.products[localProductIndex].image_id = imageId;
        } catch (e) {
            this.productLoading = false;
            // Todo: Exception swallowing
        }
        try {
            if (this.currentProductIndex === localProductIndex) {
                this.imagesLoading = true;
            }

            this.products[localProductIndex].image_id = this.products[localProductIndex].image_id?.replace('small', 'original');

            const productImages: Array<IImage> = await this._dataService
                .getProductImages(this.products[localProductIndex].style_id)
                .toPromise();
            this.products[localProductIndex].images = productImages.map((image: IImage) => ({
                type: 'imageViewer',
                data: {
                    src: image.url,
                    thumb: image.url
                }
            }));

            if (this.currentProductIndex === localProductIndex) {
                this.imagesLoading = false;
            }
        } catch (e) {
            this.imagesLoading = false;
            // Todo: Exception swallowing
        }
        await this.getProductPerformanceItems(localProductIndex);
        await this.getProductKeyMetrics(
            localProductIndex,
            this.timePeriod,
            this.timeRange,
            locationCompany,
            locationDivision,
            locationArea,
            locationRegion,
            locationStore
        );
        await this.getProductOrders(localProductIndex);
        await this.getComments(localProductIndex);
        if (this.currentProductIndex === localProductIndex) {
            this.productLoading = false;
        }
    }

    public galleryPrev(): void {
        const galleryRef: GalleryRef = this._gallery.ref(this.galleryId);
        galleryRef.prev();
    }

    public galleryNext(): void {
        const galleryRef: GalleryRef = this._gallery.ref(this.galleryId);
        galleryRef.next();
    }

    public toggleComments(): void {
        this.commentsShow = !this.commentsShow;
    }

    public async getComments(productIndex: number): Promise<void> {
        if (this.currentProductIndex === productIndex) {
            this.commentsLoading = true;
        }

        this.products[productIndex].comments = await this._commentService
            .getComments(this.products[productIndex].style_id);

        if (this.currentProductIndex === productIndex) {
            this.commentsLoading = false;
        }
    }

    public async postComment(productIndex: number): Promise<void> {
        if (this.inputs.comment.length) {
            this.commentsLoading = true;

            const data: {
                user_id: string;
                comment_body: string;
                comment_id: number;
                style_id: string;
            } = {
                comment_id: 0,
                style_id: this.products[productIndex].style_id,
                user_id: this.originalData.user_id,
                comment_body: this.inputs.comment
            };

            await this._commentService.saveComment(data);
            // if (!response) {
            this.inputs.comment = '';
            await this.getComments(this.currentProductIndex);
            // }

            this.triggerCommentIndicatorOnClose = true;

            this.commentsLoading = false;
        }
    }

    public async getProductKeyMetrics(
        productIndex: number,
        timePeriod: string | Array<string>,
        timeRange: string | Array<string>,
        locationCompany: string | Array<string>,
        locationDivision: string | Array<string>,
        locationArea: string | Array<string>,
        locationRegion: string | Array<string>,
        locationStore: string | Array<string>
    ): Promise<void> {
        if (this.currentProductIndex === productIndex) {
            this.productKeyMetricsLoading = true;
        }
        this.products[productIndex].productKeyMetrics = [];

        this.products[productIndex].productKeyMetrics = await this._dataService
            .getProductKeyMetrics(
                this.products[productIndex].style_id,
                timePeriod,
                timeRange,
                locationCompany,
                locationDivision,
                locationArea,
                locationRegion,
                locationStore
            )
            .toPromise();
        if (this.currentProductIndex === productIndex) {
            this.productKeyMetricsLoading = false;
        }
    }

    public async toggleKeyMetricsAll(): Promise<void> {
        if (!this.keyMetricsAll) {
            await this.getProductKeyMetrics(this.currentProductIndex, 'All', '', '', '', '', '', '');
        } else {
            await this.getProductKeyMetrics(
                this.currentProductIndex,
                this.originalData.time_period,
                this.originalData.time_range,
                this.originalData.location_company,
                this.originalData.location_division,
                this.originalData.location_area,
                this.originalData.location_region,
                this.originalData.location_store
            );
        }
    }

    public async getProductOrders(productIndex: number): Promise<void> {
        if (this.currentProductIndex === productIndex) {
            this.productOrdersLoading = true;
        }

        this.products[productIndex].productOrderData = await this._dataService
            .getProductOrders(this.products[productIndex].style_id)
            .toPromise();

        if (this.products[productIndex].productOrderData.length) {
            this.products[productIndex].productOrderColumns = Object.keys(this.products[productIndex].productOrderData[0]).filter(
                (e: string) => e !== 'style_id' && e !== 'order_no'
            );

            this.inputs.order = `${this.products[productIndex].productOrderData[0].order_no}`;
            this.onProductOrderSelectChange(productIndex);
        }

        if (this.currentProductIndex === productIndex) {
            this.productOrdersLoading = false;
        }
    }

    public onProductOrderSelectChange(productIndex: number): void {
        if (this.inputs.order !== '') {
            this.productOrderDataSelected = this.products[productIndex].productOrderData.filter(
                (obj: { style_id: string; order_no: string }) => obj.order_no === this.inputs.order
            );
        } else {
            this.productOrderDataSelected = [];
        }
    }

    public async getProductPerformanceItems(productIndex: number): Promise<void> {
        try {
            this.inputs.performance_detail = JSON.parse(localStorage.getItem('inputs.performance_detail'));
            const exampleChart: string = this.inputs.performance_detail;

            if (this.currentProductIndex === productIndex) {
                this.productPerformanceLoading = true;
            }

            const response: string = await this._dataService.getProductPerformanceItems().toPromise();
            this.products[productIndex].productPerformanceItems = JSON.parse(response);

            if (this.products[productIndex].productPerformanceItems.length) {
                this.products[productIndex].productPerformanceData = new Array(
                    this.products[productIndex].productPerformanceItems.length
                ).fill([], 0, this.products[productIndex].productPerformanceItems.length);

                this.inputs.performance_detail = `${exampleChart}`;
                await this.onProductPerformanceItemSelectChange(this.currentProductIndex);

                if (this.currentProductIndex === productIndex) {
                    this.productPerformanceLoading = false;
                }
            }
        } catch (e) {
            this.productPerformanceLoading = false;
        }
    }

    public async onProductPerformanceItemSelectChange(productIndex: number): Promise<void> {
        if (this.inputs.performance_detail !== '') {
            this.productPerformanceDataSelected = this.products[productIndex]?.productPerformanceItems?.filter(
                (obj: { chart_name: string }) => obj.chart_name === this.inputs.performance_detail
            );

            if (this.productPerformanceDataSelected && this.productPerformanceDataSelected.length) {
                const dataService: string = this.productPerformanceDataSelected[0].data_service.substring(
                    0,
                    this.productPerformanceDataSelected[0].data_service.lastIndexOf('/') + 1
                );
                const index: number = this.productPerformanceDataSelected[0].sort_order - 1;

                // loader when downloading images
                // add check, to see if some, chart type is
                if (this.productPerformanceDataSelected[0].chart_type === 'TABLE') {
                    this.isShowingTable = true;
                    await this.getProductPerformanceTable(productIndex, dataService, index);
                } else if (this.productPerformanceDataSelected[0].chart_type === 'CHART') {
                    this.isShowingTable = false;
                    await this.getProductPerformanceChart(productIndex, dataService, index);
                }
            }
        } else {
            this.productPerformanceDataSelected = [];
        }
    }

    public async getProductPerformanceTable(productIndex: number, api: string, index: number): Promise<void> {
        try {
            this.productPerformanceLoading = true;

            if (!this.products[productIndex].productPerformanceData[index].length) {
                this.products[productIndex].productPerformanceData[index] = await this._dataService
                    .getProductPerformance(api, this.products[productIndex].style_id)
                    .toPromise();
                // this.tableColumns =
                this.dataSource = new MatTableDataSource(this.products[productIndex].productPerformanceData[index]);
                this.dataSource.sort = this.sort;
                if (this.products[productIndex].productPerformanceData[index].length) {
                    const firstRow: Array<IProductOrderModel> = this.products[productIndex].productPerformanceData[index].slice(
                        0,
                        1
                    )[0];
                    this.products[productIndex].productPerformanceColumns = Array.from(Object.keys(firstRow));
                    this.tableColumns = this.products[productIndex].productPerformanceColumns.map((column: string) => ({
                        name: column
                    }));
                    // this.tableRows = response.map((row) => {
                    //   return Object.values(row).map(val => {
                    //     return
                    //   })
                    // });
                }

                this.productPerformanceLoading = false;
            } else {
                if (this.products[productIndex].productPerformanceData[index].length) {
                    this.dataSource = new MatTableDataSource(this.products[productIndex].productPerformanceData[index]);
                    this.dataSource.sort = this.sort;
                    const firstRow: IProductOrderModel = this.products[productIndex].productPerformanceData[index].slice(0, 1)[0];
                    this.products[productIndex].productPerformanceColumns = Array.from(Object.keys(firstRow));
                }

                this.productPerformanceLoading = false;
            }
        } catch (e) {
            this.productPerformanceLoading = false;
        }
    }

    public sortData(): void {
        this.dataSource.sort = this.sort;
    }

    public async getProductPerformanceChart(productIndex: number, api: string, index: number): Promise<void> {
        // const Chart = await importChart;

        const defaultOptions: ChartConfiguration['options'] = {
            plugins: {
                legend: {
                    display: true
                },
                datalabels: {
                    anchor: (context: Context) => {
                        const value: number = context.dataset.data[context.dataIndex] as number;
                        return +value < 1000 ? 'end' : 'center';
                    },
                    align: (ctx: Context) => {
                        const idx: number = ctx.dataIndex;
                        const val: number = ctx.dataset.data[idx] as number;

                        const datasets: Array<{
                            data: Array<number>;
                            // Todo: Not all the types are export by chartjs
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        }> = ctx.chart.data.datasets as any;
                        let min: number;
                        let max: number;
                        let i: number;
                        let ilen: number;
                        let ival: number;

                        min = max = val;

                        for (i = 0, ilen = datasets.length; i < ilen; ++i) {
                            if (i === ctx.datasetIndex) {
                                continue;
                            }

                            ival = datasets[i].data[idx];
                            min = Math.min(min, ival);
                            max = Math.max(max, ival);

                            if (val > min && val < max) {
                                return 'center';
                            }
                        }

                        return val <= min ? 'start' : 'end';
                    },
                    clip: false,
                    clamp: true,
                    backgroundColor: '#f1f1f1',
                    font: {
                        weight: 'bolder'
                    }
                }
            }
        };

        if (this.products) {
            if (this.products[productIndex]) {
                let chartConfig: ChartConfiguration = this.products[productIndex].productPerformanceData[index];
                if (Object.keys(chartConfig).length === 0) {
                    if (productIndex === this.currentProductIndex) {
                        this.productPerformanceLoading = true;
                    }

                    // Wait for dynamic import resolution
                    const response: Array<IProductPerformance> = await this._dataService
                        .getProductPerformance(api, this.products[productIndex].style_id)
                        .toPromise();
                    if (this.products[productIndex] !== undefined) {
                        this.products[productIndex].productPerformanceData[index] = response;
                        chartConfig = this.products[productIndex].productPerformanceData[index];
                        this.chartType = chartConfig.type;
                    }

                    if (productIndex === this.currentProductIndex) {
                        this.chartType = chartConfig.type;
                        this.chartData = {
                            ...chartConfig.data,
                            // Todo: Not all the types are export by chartjs
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            datasets: chartConfig.data.datasets.map(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (t: { lineTension: number }) =>
                                    ({
                                        ...t,
                                        pointBackgroundColor: '#FFFFFF',
                                        pointBorderColor: '#FFFFFF',
                                        pointHoverBackgroundColor: '#FFFFFF',
                                        pointHoverBorderColor: '#FFFFFF',
                                        tension: t.lineTension,
                                        type: this.chartType
                                        // Todo: Not all the types are export by chartjs
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    } as any)
                            )
                        };
                        this.chartOptions = {
                            ...defaultOptions,
                            ...chartConfig.options
                        } as ChartConfiguration['options'];
                        this.chartOptions.responsive = true;
                        this.chartOptions.maintainAspectRatio = false;

                        this.chartOptions.animation = false;
                        this.chart.update();

                        this.productPerformanceLoading = false;
                    }
                } else {
                    this.chartType = chartConfig.type;

                    this.chartData = {
                        ...chartConfig.data,
                        // Todo: Not all the types are export by chartjs
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        datasets: chartConfig.data.datasets.map(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            (t: { lineTension: number }) =>
                                ({
                                    ...t,
                                    pointBackgroundColor: '#FFFFFF',
                                    pointBorderColor: '#FFFFFF',
                                    pointHoverBackgroundColor: '#FFFFFF',
                                    pointHoverBorderColor: '#FFFFFF',
                                    tension: t.lineTension
                                    // Todo: Not all the types are export by chartjs
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                } as any)
                        )
                    };
                    this.chartOptions = {
                        ...defaultOptions,
                        ...chartConfig.options
                    } as ChartConfiguration['options'];
                    this.chartOptions.responsive = true;
                    this.chartOptions.maintainAspectRatio = false;
                    chartConfig.plugins = [ChartDataLabels];
                    // Todo: investigate chart config not having height
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    chartConfig.height = 100;
                    this.chartOptions.animation = false;
                    this.chart.update();
                }
            }
        }
    }

    // public keyMetricsFontSize(value: string): number {
    //     const windowWidth = window.innerWidth;
    //     let fontSize = 36;
    //
    //     if (windowWidth < 1920) {
    //         switch (true) {
    //             case value.length === 5:
    //                 fontSize = 34;
    //                 break;
    //             case value.length === 6:
    //                 fontSize = 28;
    //                 break;
    //             case value.length >= 7:
    //                 fontSize = 24;
    //                 break;
    //             default:
    //                 fontSize = fontSize;
    //         }
    //     }
    //
    //     return fontSize;
    // }

    public async viewProduct(direction: string): Promise<void> {
        let newProductId: string;

        if (direction === 'prev') {
            this.products.map((product: IProduct, index: number) => {
                if (product.style_id === this.products[this.currentProductIndex].style_id) {
                    newProductId =
                        index - 1 === -1 ? this.products[this.products.length - 1].style_id : this.products[index - 1].style_id;
                }
            });
        } else if (direction === 'next') {
            this.products.map((product: IProduct, index: number) => {
                if (product.style_id === this.products[this.currentProductIndex].style_id) {
                    newProductId =
                        index + 1 >= this.products.length ? this.products[0].style_id : this.products[index + 1].style_id;
                }
            });
        }

        this.inputs = {
            order: '',
            performance_detail: '',
            comment: ''
        };

        await this.loadProductData(
            this.products.indexOf(this.products.filter((product: IProduct) => product.style_id === newProductId)[0])
        );

        /* this.comments = [];
    this.images = [];
    this.productKeyMetrics = [];
    this.productStyleColumns = [];
    this.productOrderColumns = [];
    this.productOrderData = [];
    this.productOrderDataSelected = [];
    this.productPerformanceItems = [];
    this.productPerformanceData= [];
    this.productPerformanceDataSelected = [];
    this.productPerformanceColumns = []; */

        /* this.dialogRef.close( {
      style_id: this.product.style_id,
      direction: direction
    }); */
    }

    // this.getGridData();

    // getGridData() {
    //   // this.gridSource.paginator = this.paginator;
    //   this.dataSource.data = this.products;
    //   this.dataSource.sort = this.sort;
    // }

    // sortData(event) {
    //     this.products = this.dataSource.sortData(
    //     this.dataSource.data,
    //     this.dataSource.sort
    //   );
    // }
    public async exportExcel(): Promise<void> {
        await this._excelExportService.exportToExcel({
            data: this.dataSource.filteredData.slice().map((item: IProductPerformance) => {
                const {
                    ...newItem
                }: IProductPerformance & {
                    image_id?: string;
                    image?: string | ArrayBuffer | null;
                } = { ...item };
                return newItem;
            }),
            fileName: `PerformanceDetails`
        });
    }

    public sortComments(commentOne: IProductComment, commentTwo: IProductComment): number {
        const sortDirection: number = -1; // Newest to oldest
        const dateOne: Date = new Date(commentOne.comment_time);
        const dateTwo: Date = new Date(commentTwo.comment_time);

        // If both dates are undefined, they're seen as equal
        if (!dateOne && !dateTwo) return 0;
        // If dateOne is undefined, it's seen as older (moved to the bottom)
        if (!dateOne) return 1;
        // If dateTwo is undefined, it's seen as older (moved to the bottom)
        if (!dateTwo) return -1;
        // Compare the dates
        return (dateOne.getTime() - dateTwo.getTime())*sortDirection;
    }
}
