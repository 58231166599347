import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard/dashboard.component';
import { AuthGuardService as AuthGuard } from './core/auth/auth-guard.service';

const routes: Routes = [
    {
        path: 'auth',
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'dashboard',
        redirectTo: 'analytics/gallery'
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        // loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
        // canActivate: [AuthGuard]
    },
    {
        path: 'allocations/workbench',
        data: {
            expectedRole: 'allocations'
        },
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/workbench/workbench.module').then(m => m.WorkbenchModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'allocations/settings',
        data: {
            expectedRole: 'allocations'
        },
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'allocations/summaries',
        data: {
            expectedRole: 'allocations'
        },
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/summaries/summaries.module').then(m => m.SummariesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'analytics/:view',
        data: {
            expectedRole: 'analytics'
        },
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/listing/listing.module').then(m => m.ListingModule),
        canActivate: [AuthGuard],
        component: DashboardComponent
    },
    {
        path: 'user-manager/:view',
        data: {
            expectedRole: 'users'
        },
        canActivate: [AuthGuard],
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/user-manager/user-manager.module').then(m => m.UserManagerModule)
    },
    {
        path: 'picture-manager/:view',
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/picture-manager/picture-manager.module').then(m => m.PictureManagerModule)
    },
    {
        path: 'maps/:view',
        data: {
            expectedRole: 'maps'
        },
        canActivate: [AuthGuard],
        // eslint-disable-next-line @typescript-eslint/promise-function-async,@typescript-eslint/typedef
        loadChildren: () => import('./features/maps/maps.module').then(m => m.MapsModule)
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
