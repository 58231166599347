<div [ngClass]="styling.wrapper()">
    <div class="backdrop" (click)="onAction('DISMISS')"></div>

    <dialog [ngClass]="styling.content()" open>
        <div [ngClass]="styling.header()">
            <div class="top">
                <pt-ui-button
                    *ngIf="icons?.left"
                    [icon]="icons.left.variant"
                    (click)="onAction('ICON_LEFT')"
                    [disabled]="icons.left.status === 'disabled'"
                ></pt-ui-button>

                <div class="title">
                    <pt-ui-text size="xl">{{ title }}</pt-ui-text>
                </div>

                <pt-ui-button
                    *ngIf="icons?.right"
                    [icon]="icons.right.variant"
                    (click)="onAction('ICON_RIGHT')"
                    [disabled]="icons.right.status === 'disabled'"
                ></pt-ui-button>
            </div>

            <div class="sections" *ngIf="tabs && tabs?.labels?.length > 0">
                <div
                    *ngFor="let item of tabs.labels; let index = index"
                    [ngClass]="styling.tab(index)"
                    (click)="onSetActive(index)"
                >
                    <span>{{ item }}</span>
                    <span class="circle">{{ tabs.counts[index] > 99 ? '99+' : tabs.counts[index] }}</span>
                </div>
            </div>
        </div>

        <div class="inner">
            <ng-content select="[modal-content]"></ng-content>
            <div *ngIf="content.inner(0)"><ng-content select="[modal-tab-0]"></ng-content></div>
            <div *ngIf="content.inner(1)"><ng-content select="[modal-tab-1]"></ng-content></div>
            <div *ngIf="content.inner(2)"><ng-content select="[modal-tab-2]"></ng-content></div>
            <div *ngIf="content.inner(3)"><ng-content select="[modal-tab-3]"></ng-content></div>
            <div *ngIf="content.inner(4)"><ng-content select="[modal-tab-4]"></ng-content></div>
            <div *ngIf="content.inner(5)"><ng-content select="[modal-tab-5]"></ng-content></div>
            <div *ngIf="content.inner(6)"><ng-content select="[modal-tab-6]"></ng-content></div>
        </div>

        <div [ngClass]="styling.footer()">
            <pt-ui-button
                *ngIf="buttons?.secondary"
                [label]="buttons?.secondary.label"
                [loading]="buttons?.secondary.loading"
                [disabled]="buttons?.secondary.disabled"
                (click)="onAction('BUTTON_SECONDARY')"
            ></pt-ui-button>

            <pt-ui-button
                *ngIf="buttons?.primary"
                [label]="buttons?.primary.label"
                [loading]="buttons?.primary.loading"
                [disabled]="buttons?.primary.disabled"
                importance="primary"
                (click)="onAction('BUTTON_PRIMARY')"
            ></pt-ui-button>
        </div>
    </dialog>
</div>
