import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent, SelectionComponent } from '../../../../elements';
import * as schema from '../../schema';

@Component({
    standalone: true,
    selector: 'pt-ui-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss'],
    imports: [TextComponent, CommonModule, SelectionComponent]
})
export class SectionHeaderComponent implements schema.interfaces.SectionHeader {
    @Input() public loading: boolean = false;
    @Input() public label: string = '';
    @Input() public options: string[] = [];
    @Output() public readonly update = new EventEmitter<string>();

    public content = {
        options: () => this.options.join(', ')
    };

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--selectable': this.options.length > 0
        })
    };

    public onSelection(option: string) {
        this.label = option;
        this.update.emit(option);
    }
}
