import { Injectable } from '@angular/core';

const appPrefix: string = 'LYNKD-PATTERN-';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {


  public setItem<T>(key: string, value: T): void {
      sessionStorage.setItem(`${appPrefix}${key}`, JSON.stringify(value));
  }

  public getItem<T>(key: string): T {
    return JSON.parse(sessionStorage.getItem(`${appPrefix}${key}`));
  }

  public removeItem(key: string): void {
      sessionStorage.removeItem(`${appPrefix}${key}`);
  }

  /** Tests that   exists, can be written to, and read from. */
  public testLocalStorage(): void {
    const testValue: string = 'testValue';
    const testKey: string = 'testKey';

    const errorMessage: string = 'sessionStorage did not return expected value';

    this.setItem(testKey, testValue);
    const retrievedValue: string = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }

  public static loadInitialState(): Record<string, string | Record<string, string>> {
    return Object.keys(sessionStorage).reduce((state: Record<string, string | Record<string, string>>, storageKey: string) => {
      if (storageKey.includes(appPrefix)) {
        const stateKeys: Array<string> = storageKey
            .replace(appPrefix, '')
            .toLowerCase()
            .split('.')
            .map((key: string) =>
                key
                    .split('-')
                    .map((token: string, index: number) =>
                        index === 0
                            ? token
                            : token.charAt(0).toUpperCase() + token.slice(1)
                    )
                    .join('')
            );
        let currentStateRef: Record<string,  string | Record<string, string>> = state;
        stateKeys.forEach((key: string, index: number) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(sessionStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key] as Record<string, string | Record<string, string>>;
        });
      }
      return state;
    }, {});
  }
}
