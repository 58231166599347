import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'pt-ui-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss'],
    imports: [CommonModule]
})
export class TextComponent {
    @Input() public lines: number = 0;
    @Input() public importance: 'primary' | 'secondary' = 'primary';
    @Input() public size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' = 'm';
    @Input() public loading: boolean = false;
    @Input() public background: 'light' | 'dark' = 'light';
    @Input() public compact: boolean = false;
    @Input() public format: 'normal' | 'bold' = 'normal';

    public styling = {
        loadingWidth: (): string => {
            if (!this.loading) return '';
            return `${this.loading}rem`;
        },

        loading: () => ({
            loading: true,
            [`loading--${this.size}`]: true,
            'loading--dark': this.background === 'dark'
        }),

        wrapperInline: () => ({
            '-webkit-line-clamp': this.lines > 1 && this.lines
        }),

        wrapper: () => ({
            wrapper: true,
            [`wrapper--${this.size}`]: true,
            'wrapper--single-line': Number(this.lines) === 1,
            'wrapper--multi-line': Number(this.lines) > 1,
            'wrapper--secondary': this.importance === 'secondary',
            'wrapper--dark': this.background === 'dark',
            'wrapper--compact': this.compact,
            'wrapper--bold': this.format === 'bold',
            'wrapper--normal': this.format === 'normal'
        })
    };
}
