import * as schema from '../../schema';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent, IconComponent } from '../../../../elements';
import * as h from '../../helpers';

@Component({
    standalone: true,
    selector: 'pt-ui-level-item',
    templateUrl: './level-item.component.html',
    styleUrls: ['./level-item.component.scss'],
    imports: [TextComponent, CommonModule, IconComponent]
})
export class LevelItemComponent implements schema.interfaces.LevelItem {
    @Input() public selected = null;
    @Input() public open = null;
    @Input() public count = 0;
    @Input() public label = '';
    @Input() public loading: boolean = false;

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--selectable': h.general.calcIfLevelItemSelectable({ open: this.open, selected: this.selected })
        }),
        open: () => ({
            open: true,
            'open--active': this.open
        })
    };

    public content = {
        selected: () => typeof this.selected === 'boolean',
        indicator: () => typeof this.selected === 'boolean' || typeof this.count === 'number',
        count: () => typeof this.count === 'number' && typeof this.selected !== 'boolean',
        open: () => typeof this.open === 'boolean'
    };
}
