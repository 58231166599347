import { Component, OnInit } from '@angular/core';
import { IProductDisplayAttribute } from '../../core/models/product-display-attribute.model';
import { Observable } from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from '../../core/models/option.model';
import { MatListOption } from '@angular/material/list';
import { map, startWith } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AttributeService } from '../../shared/services/attribute.service';

/**
 * @title List with selection
 */
@Component({
    selector: 'lynkd-pattern-display-attributes',
    templateUrl: './display-attributes-select.component.html',
    styleUrls: ['./display-attributes-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DisplayAttributesSelectComponent
        }
    ]
})
export class DisplayAttributesSelectComponent implements ControlValueAccessor, OnInit {
    public searchControl: FormControl = new FormControl();
    public $filteredAttributes: Observable<Array<Option>>;
    public allAttributes: Array<Option>;
    public onChange: (value: Array<string>) => void;
    public attributesControl: FormControl = new FormControl();
    public allSelected: boolean = false;

    public constructor(private readonly _attributeService: AttributeService) {}

    public async ngOnInit(): Promise<void> {
        const result: Array<IProductDisplayAttribute> = await this._attributeService
            .getProductDisplayAttributes();
        this.allAttributes = result.map((x: IProductDisplayAttribute): Option => new Option(x));
        this.$filteredAttributes = this.searchControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filter(value))
        );
        this.allSelected = this.attributesControl?.value?.length === this.allAttributes.length;
    }

    public writeValue(obj: Array<string>): void {
        this.attributesControl.setValue(obj);
    }

    public registerOnChange(onChange: (value: Array<string>) => void): void {
        this.onChange = onChange;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public registerOnTouched(fn: (value: Array<string>) => void): void {
        // Implementation of interface method
    }

    // public optionClicked(event: Event): void {
    //     event.stopPropagation();
    //     this.onChange(this.attributesControl.value);
    // }

    public selectAll($event: MatCheckboxChange): void {
        this.allSelected = $event.checked;

        if (this.allSelected) {
            this.attributesControl.setValue(this.allAttributes.map((t: Option) => t.value.toString()));
            this.onChange(this.attributesControl.value);
        } else {
            this.attributesControl.setValue([]);
            this.onChange(this.attributesControl.value);
        }
    }

    public selectionChange(options: Array<MatListOption>): void {
        let value: Array<string> = this.attributesControl.value || [];
        options.forEach((option: MatListOption) => {
            if (option.selected) {
                value.push(option.value);
            } else {
                value = value.filter((x: string) => x !== option.value);
            }
        });
        this.attributesControl.setValue(value);
        this.onChange(this.attributesControl.value);
    }

    private filter(value: string): Array<Option> {
        const filterValue: string = value.toLowerCase();
        return this.allAttributes.filter((option: Option) =>
            (option.value as IProductDisplayAttribute).display_name.toLowerCase().includes(filterValue)
        );
    }
}
