<div class='col-auto'>
    <!--    <p *ngIf='!filtersApplied' class='filters-data'>No filters applied yet</p>-->
    <div class='row'>
        <div class='row'>
            <div class='col'>
                <div class='filters-data'>
                    <button mat-button [matMenuTriggerFor]='exclusionsMenu' *ngIf='filterData.exclusions?.length > 0'>
                        <img src='assets/icon-filter.svg' alt='filter button' width='18px' height='auto' />
                        Exclusions

                        <span (click)='removeMainExclusion()'>
                                <img
                                    src='assets/icon-close-alt2.svg'
                                    alt='close button'
                                    width='8px'
                                    height='auto'
                                    class='remove-icon'
                                />
                            </span>
                    </button>
                    <mat-menu #exclusionsMenu='matMenu' yPosition='below'>
                        <mat-chip-list>
                            <mat-chip
                                *ngFor='let exclusion of filterData.exclusions'
                                [removable]='true'
                                (removed)='removeExclusion(exclusion)'
                                class='text-capitalize'
                            >
                                {{ exclusion.style_id | titlecase }}

                                <img
                                    src='assets/icon-close-alt.svg'
                                    width='14'
                                    height='auto'
                                    alt='close button'
                                    matChipRemove
                                />
                            </mat-chip>
                        </mat-chip-list>
                    </mat-menu>
                </div>
            </div>
        </div>
        <!--        <div class='row' *ngIf='filtersApplied'>-->
        <div class='row'>
            <div class='col'>
                <div class='filters-data'>
                    <button
                        mat-button
                        [matMenuTriggerFor]='timeMenu'
                        *ngIf='
                            filterData.time_period?.length ||
                            filterData.time_range?.length ||
                            filterData.time_year?.length ||
                            filterData.time_quarter?.length ||
                            filterData.time_month?.length
                        '
                    >
                        <img src='assets/icon-filter.svg' width='18px' height='18' alt='filter' />
                        <div class='d-inline-block breadcrumb-label'>Time period</div>
                        <div class='d-inline-block' (click)="removeMainFilter('time')">
                            <img
                                src='assets/icon-close-alt2.svg'
                                alt='close button'
                                width='8px'
                                height='auto'
                                class='remove-icon'
                            />
                        </div>
                    </button>
                    <mat-menu #timeMenu='matMenu' yPosition='below'>
                        <!-- <label class="align-right" *ngIf="workbenchFilterData.time_periods.length">({{ workbenchFilterData.time_periods.length }})</label> -->
                        <!-- <label class="align-right" *ngIf="workbenchFilterData.time_periods.length"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g fill="none" fill-rule="evenodd"><path fill="#666" fill-rule="nonzero" d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"/></g></svg></label> -->
                        <label *ngIf='filterData.time_period?.length'>Recent</label>
                        <mat-chip-list>
                            <ng-container *ngIf='filterData.time_period?.length'>
                                <mat-chip
                                    [removable]='true'
                                    (removed)="removeFilter('time_period', filterData.time_period)"
                                    class='text-capitalize'
                                >
                                    {{ filterData.time_period }}
                                    <img
                                        src='assets/icon-close-alt.svg'
                                        width='14'
                                        height='auto'
                                        alt='close button'
                                        matChipRemove
                                    />
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>

                        <label *ngIf='filterData.time_range?.length'>Range</label>
                        <mat-chip-list>
                            <ng-container *ngIf='filterData.time_range?.length'>
                                <mat-chip
                                    [removable]='true'
                                    (removed)="removeFilter('time_range', filterData.time_range)"
                                    class='text-capitalize'
                                >
                                    {{ filterData.time_range | titlecase }}
                                    <img
                                        src='assets/icon-close-alt.svg'
                                        width='14'
                                        height='auto'
                                        alt='close button'
                                        matChipRemove
                                    />
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>

                        <label *ngIf='filterData.time_year?.length'>Year</label>
                        <mat-chip-list>
                            <ng-container *ngIf='filterData.time_year?.length'>
                                <mat-chip
                                    *ngFor='let time_year of filterData.time_year'
                                    [removable]='true'
                                    (removed)="removeFilter('time_year', time_year)"
                                    class='text-capitalize'
                                >
                                    {{ time_year | titlecase }}
                                    <img
                                        src='assets/icon-close-alt.svg'
                                        width='14'
                                        height='auto'
                                        alt='close button'
                                        matChipRemove
                                    />
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>

                        <label *ngIf='filterData.time_quarter?.length'>Quarter</label>
                        <mat-chip-list>
                            <ng-container *ngIf='filterData.time_quarter?.length'>
                                <mat-chip
                                    *ngFor='let time_quarter of filterData.time_quarter'
                                    [removable]='true'
                                    (removed)="removeFilter('time_quarter', time_quarter)"
                                    class='text-capitalize'
                                >
                                    {{ time_quarter | titlecase }}
                                    <img
                                        src='assets/icon-close-alt.svg'
                                        width='14'
                                        height='auto'
                                        alt='close button'
                                        matChipRemove
                                    />
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>

                        <label *ngIf='filterData.time_month?.length'>Month</label>
                        <mat-chip-list>
                            <ng-container *ngIf='filterData.time_month?.length'>
                                <mat-chip
                                    *ngFor='let time_month of filterData.time_month'
                                    [removable]='true'
                                    (removed)="removeFilter('time_month', time_month)"
                                    class='text-capitalize'
                                >
                                    {{ time_month | titlecase }}
                                    <img
                                        src='assets/icon-close-alt.svg'
                                        width='14'
                                        height='auto'
                                        alt='close button'
                                        matChipRemove
                                    />
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]='departmentMenu' *ngIf='filterData.department?.length'>
                        <img src='assets/icon-filter.svg' width='18px' height='auto' alt='filter' />
                        <div class='d-inline-block breadcrumb-label'>Department</div>
                        <div class='d-inline-block' (click)="removeMainFilter('department')">
                            <img
                                src='assets/icon-close-alt2.svg'
                                alt='close button'
                                width='8px'
                                height='auto'
                                class='remove-icon'
                            />
                        </div>
                    </button>
                    <mat-menu #departmentMenu='matMenu' yPosition='below'>
                        <mat-chip-list>
                            <mat-chip
                                *ngFor='let department of filterData.department'
                                [removable]='true'
                                (removed)="removeFilter('department', department)"
                                class='text-capitalize'
                            >
                                {{ department | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]='subDepartmentMenu'
                            *ngIf='filterData.sub_department?.length'>
                        <img src='assets/icon-filter.svg' width='18px' height='auto' alt='filter' />
                        <div class='d-inline-block breadcrumb-label'>Sub Department</div>
                        <div class='d-inline-block' (click)="removeMainFilter('sub_department')">
                            <img
                                src='assets/icon-close-alt2.svg'
                                alt='close button'
                                width='8px'
                                height='auto'
                                class='remove-icon'
                            />

                        </div>
                    </button>
                    <mat-menu #subDepartmentMenu='matMenu' yPosition='below'>
                        <mat-chip-list>
                            <mat-chip
                                *ngFor='let sub_department of filterData.sub_department'
                                [removable]='true'
                                (removed)="removeFilter('sub_department', sub_department)"
                                class='text-capitalize'
                            >
                                {{ sub_department | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]='categoryMenu' *ngIf='filterData.category?.length'>
                        <img src='assets/icon-filter.svg' width='18px' height='auto' alt='filter' />
                        <div class='d-inline-block breadcrumb-label'>Category</div>
                        <div class='d-inline-block' (click)="removeMainFilter('category')">
                            <img
                                src='assets/icon-close-alt2.svg'
                                alt='close button'
                                width='8px'
                                height='auto'
                                class='remove-icon'
                            />
                        </div>
                    </button>
                    <mat-menu #categoryMenu='matMenu' yPosition='below'>
                        <mat-chip-list>
                            <mat-chip
                                *ngFor='let category of filterData.category'
                                [removable]='true'
                                (removed)="removeFilter('category', category)"
                                class='text-capitalize'
                            >
                                {{ category | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]='productTypeMenu' *ngIf='filterData.product_type?.length'>
                        <img src='assets/icon-filter.svg' width='18px' height='auto' alt='filter' />
                        <div class='d-inline-block breadcrumb-label'>Product type</div>
                        <div class='d-inline-block' (click)="removeMainFilter('product_type')">
                            <img
                                src='assets/icon-close-alt2.svg'
                                alt='close button'
                                width='8px'
                                height='auto'
                                class='remove-icon'
                            />
                        </div>
                    </button>
                    <mat-menu #productTypeMenu='matMenu' yPosition='below'>
                        <mat-chip-list>
                            <mat-chip
                                *ngFor='let product_type of filterData.product_type'
                                [removable]='true'
                                (removed)="removeFilter('product_type', product_type)"
                                class='text-capitalize'
                            >
                                {{ product_type | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>
                    </mat-menu>
                    <button
                        mat-button
                        [matMenuTriggerFor]='locationMenu'
                        *ngIf='filterData.location_company?.length ||
                            filterData.location_division?.length ||
                            filterData.location_area?.length ||
                            filterData.location_region?.length ||
                            filterData.location_store?.length
                        '
                    >
                        <img src='assets/icon-filter.svg' width='18px' height='auto' alt='filter' />
                        <div class='d-inline-block breadcrumb-label'>Location</div>
                        <div class='d-inline-block' (click)="removeMainFilter('location')">
                            <img
                                src='assets/icon-close-alt2.svg'
                                alt='close button'
                                width='8px'
                                height='auto'
                                class='remove-icon'
                            />
                        </div>
                    </button>
                    <mat-menu #locationMenu='matMenu' yPosition='below'>
                        <label *ngIf='filterData.location_company?.length'>Companies</label>
                        <mat-chip-list *ngIf='filterData.location_company?.length'>
                            <mat-chip
                                *ngFor='let location_company of filterData.location_company'
                                [removable]='true'
                                (removed)="removeFilter('location_company', location_company)"
                                class='text-capitalize'
                            >
                                {{ location_company | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>

                        <label *ngIf='filterData.location_division?.length'>Divisions</label>
                        <mat-chip-list *ngIf='filterData.location_division?.length'>
                            <mat-chip
                                *ngFor='let location_division of filterData.location_division'
                                [removable]='true'
                                (removed)="removeFilter('location_division', location_division)"
                                class='text-capitalize'
                            >
                                {{ location_division | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>

                        <label *ngIf='filterData.location_area?.length'>Areas</label>
                        <mat-chip-list *ngIf='filterData.location_area?.length'>
                            <mat-chip
                                *ngFor='let location_area of filterData.location_area'
                                [removable]='true'
                                (removed)="removeFilter('location_area', location_area)"
                                class='text-capitalize'
                            >
                                {{ location_area | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>

                        <label *ngIf='filterData.location_region?.length'>Regions</label>
                        <mat-chip-list *ngIf='filterData.location_region?.length'>
                            <mat-chip
                                *ngFor='let location_region of filterData.location_region'
                                [removable]='true'
                                (removed)="removeFilter('location_region', location_region)"
                                class='text-capitalize'
                            >
                                {{ location_region | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>

                        <label *ngIf='filterData.location_store?.length'>Stores</label>
                        <mat-chip-list *ngIf='filterData.location_store?.length'>
                            <mat-chip
                                *ngFor='let location_store of filterData.location_store'
                                [removable]='true'
                                (removed)="removeFilter('location_store', location_store)"
                                class='text-capitalize'
                            >
                                {{ location_store | titlecase }}
                                <img src='assets/icon-close-alt.svg' width='14' height='auto' alt='close button'
                                     matChipRemove />
                            </mat-chip>
                        </mat-chip-list>
                    </mat-menu>

                    <button mat-button [matMenuTriggerFor]='metricRangeMenu' *ngIf='checkActiveMetricRanges'>
                        <img src='assets/icon-filter.svg' width='18px' height='auto' alt='filter' />
                        <div class='d-inline-block breadcrumb-label'>Metric range</div>
                        <div class='d-inline-block' (click)="removeMainFilter('metric_range')">
                            <img
                                src='assets/icon-close-alt2.svg'
                                alt='close button'
                                width='8px'
                                height='auto'
                                class='remove-icon'
                            />
                        </div>
                    </button>
                    <mat-menu #metricRangeMenu='matMenu' yPosition='below'>
                        <mat-chip-list class='column'>
                            <ng-container *ngFor='let metric_range of filterData.metric_range; let index = index'>
                                <mat-chip
                                    *ngIf='metric_range.active'
                                    [removable]='true'
                                    (removed)="removeFilter('metric_range', metric_range)"
                                    class='text-capitalize'
                                >
                                    {{ metric_range.metric_name | displayName }}
                                    {{ metric_range.values[0] }} - {{ metric_range.values[1] }}
                                    <img
                                        src='assets/icon-close-alt.svg'
                                        width='14'
                                        height='auto'
                                        alt='close button'
                                        matChipRemove
                                    />
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </mat-menu>
                    <ng-container *ngFor='let meta_data of filterData.meta_data | keyvalue'>
                        <button
                            mat-button
                            [matMenuTriggerFor]='metaDataMenu'
                            *ngIf='meta_data.value?.length'
                            class='text-capitalize'
                        >
                            <img src='assets/icon-filter.svg' width='18px' height='auto' />
                            <div class='d-inline-block breadcrumb-label'>{{ meta_data.key | displayName | titlecase }}
                            </div>
                            <div class='d-inline-block' (click)="removeMainFilter('meta_data', meta_data.key)">
                                <img
                                    src='assets/icon-close-alt2.svg'
                                    alt='close button'
                                    width='8px'
                                    height='auto'
                                    class='remove-icon'
                                />
                            </div>
                        </button>
                        <mat-menu #metaDataMenu='matMenu' yPosition='below'>
                            <mat-chip-list>
                                <mat-chip
                                    *ngFor='let meta_data_values of meta_data.value'
                                    [removable]='true'
                                    (removed)="removeFilter('meta_data', meta_data_values, meta_data.key)"
                                    class='text-capitalize'
                                >
                                    {{ meta_data_values | titlecase }}
                                    <img
                                        src='assets/icon-close-alt.svg'
                                        width='14'
                                        height='auto'
                                        alt='close button'
                                        matChipRemove
                                    />
                                </mat-chip>
                            </mat-chip-list>
                        </mat-menu>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
