import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "stringReplace"})
export class FormatStringReplacePipe implements PipeTransform {
  public transform(value: string, strToReplace: string, replacementStr: string): string {

    if (!value || ! strToReplace || ! replacementStr) {
      return value;
    }

    return value.replace(strToReplace, replacementStr);
  }
}
