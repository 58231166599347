import * as schema from './icon.schema';

export const VARIANTS: schema.Element['variant'][] = [
    'none',
    'check',
    'add',
    'right',
    'left',
    'forward',
    'search',
    'down',
    'expand'
];
