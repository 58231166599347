<nz-table [nzData]="columns" [nzShowPagination]="false" [nzBordered]="true" [nzLoading]="loading">
    <tbody>
        <tr *ngFor="let column of columns">
            <td [nzAlign]="'center'" class="header-cell">{{ column | uppercase }}</td>
            <ng-container [ngSwitch]="column">
                <ng-container *ngSwitchCase="'total'">
                    <td [nzAlign]="'center'" class="editable-cell">
                        <div *ngIf="editColumn !== column" (click)="startEdit(column)">
                            {{ getColumnValue(column) | shortNumber }}
                        </div>
                        <input
                            lynkdPatternAutoFocus
                            *ngIf="editColumn === column"
                            [(ngModel)]="data.total_budget"
                            (blur)="stopEdit()"
                            (keydown.enter)="stopEdit()"
                        />
                    </td>
                    <td [nzAlign]="'center'" class="font-weight-bold">%</td>
                </ng-container>
                <ng-container *ngSwitchCase="'core'">
                    <td [nzAlign]="'center'">{{ getColumnValue(column) | shortNumber }}</td>
                    <td [nzAlign]="'center'" class="editable-cell">
                        <div *ngIf="editColumn !== column" (click)="startEdit(column)">
                            {{ getColumnValue(column, true) | percentValue }}
                        </div>
                        <input
                            lynkdPatternAutoFocus
                            *ngIf="editColumn === column"
                            [(ngModel)]="data.core_percentage"
                            (blur)="stopEdit()"
                            (keydown.enter)="stopEdit()"
                        />
                    </td>
                </ng-container>
                <ng-container *ngSwitchCase="'fashion'">
                    <td [nzAlign]="'center'">{{ getColumnValue(column) | shortNumber }}</td>
                    <td [nzAlign]="'center'" class="editable-cell">
                        <div *ngIf="editColumn !== column" (click)="startEdit(column)">
                            {{ getColumnValue(column, true) | percentValue }}
                        </div>
                        <input
                            lynkdPatternAutoFocus
                            *ngIf="editColumn === column"
                            [(ngModel)]="data.fashion_percentage"
                            (blur)="stopEdit()"
                            (keydown.enter)="stopEdit()"
                        />
                    </td>
                </ng-container>
                <ng-container *ngSwitchCase="'actual'">
                    <td [nzAlign]="'center'">{{ getColumnValue(column) | shortNumber }}</td>
                    <td [nzAlign]="'center'">{{ getColumnValue(column, true) | percentValue }}</td>
                </ng-container>
            </ng-container>
        </tr>
    </tbody>
</nz-table>
