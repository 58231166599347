import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { IAppState, selectAllocationsActiveView } from '../../../core/core.state';
import { AllocationsViewModel } from '../../../features/workbench/models/allocations-view-model.enum';

@Component({
    selector: 'lynkd-pattern-allocations-views',
    templateUrl: './allocations-views.component.html',
    styleUrls: ['./allocations-views.component.scss']
})
export class AllocationsViewsComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/typedef
    public AllocationsViewModel = AllocationsViewModel;
    public activeView: AllocationsViewModel;
    private _active: boolean = true;

    public constructor(private readonly _router: Router, private readonly _store: Store<IAppState>) {
    }

    public ngOnInit(): void {
        this._store.pipe(select(selectAllocationsActiveView),
            takeWhile(() => this._active),
            distinctUntilChanged((previous: AllocationsViewModel, current: AllocationsViewModel) => previous === current))
            .subscribe((result: AllocationsViewModel) => {
                this.activeView = result;
            });
    }

    public async changeView(view: AllocationsViewModel): Promise<boolean> {
        this.activeView = view;
        return this._router.navigate(['/allocations', view]);
    }

    public ngOnDestroy(): void {
        this._active = true;
    }
}
