import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Auth } from 'aws-amplify';
import { selectIsAuthenticated } from './auth.selectors';
import { IAppState } from '../core.state';
import { firstValueFrom } from 'rxjs';
import { CognitoRefreshToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { selectUserDetails } from '../store/store.selectors';
import { ApplicationMenuService } from '../../shared/services/application-menu.service';
import { IApplicationMenuItemModel } from '../../shared/models/application-menu-item.model';
import { IUserRole, IUserRoleObj } from '../models/user-roles.model';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    public constructor(
        private readonly _store: Store<IAppState>,
        private readonly _router: Router,
        private readonly _applicationMenuService: ApplicationMenuService,
        private readonly _zone: NgZone
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const expectedRole: string = route.data.expectedRole;
        const isAuthenticated: boolean = await firstValueFrom(this._store.select(selectIsAuthenticated));

        if (isAuthenticated) {
            let userDetails: { email: string; roles: Array<IUserRole> } = await firstValueFrom(
                this._store.select(selectUserDetails)
            );

            if (!userDetails) {
                try {
                    const session: CognitoUserSession = await Auth.currentSession();
                    const roles: Array<IUserRole> = (
                        JSON.parse((await Auth.currentUserInfo()).attributes['custom:roles']) as IUserRoleObj
                    ).data;

                    userDetails = { email: session.getIdToken().payload.email, roles };
                } catch (e) {
                    await Auth.signOut();
                    return this._router.navigate(['/auth']);
                }
            }

            const menuItems: Array<string> = (await firstValueFrom(this._applicationMenuService.getAll())).map(
                (item: IApplicationMenuItemModel) => item.name
            );

            if (!userDetails?.roles || userDetails?.roles.length === 0) {
                try {
                    await Auth.signOut();
                    return this._router.navigate(['/auth']);
                } catch (e) {
                    // Handle auth error
                }
            }

            if (
                userDetails.roles.map((val: IUserRole) => val.module).includes(expectedRole) &&
                menuItems.includes(expectedRole)
            ) {
                return true;
            } else {
                for (const role of userDetails.roles) {
                    if (menuItems.includes(role.module)) {
                        if (role.module === 'allocations') {
                            await this._zone.run(async (): Promise<boolean> => this._router.navigate(['/allocations/workbench']));
                            return this._router.navigate(['/allocations/workbench']);
                        } else if (role.module === 'analytics') {
                            await this._zone.run(async (): Promise<boolean> => this._router.navigate(['/dashboard']));
                            return this._router.navigate(['/dashboard']);
                        } else {
                            await this._zone.run(async (): Promise<boolean> => this._router.navigate([`/${role}`]));
                            return this._router.navigate([`/${role}`]);
                        }
                    }
                }
            }
        } else {
            return false;
        }
    }

    public async getToken(): Promise<string> {
        try {
            const session: CognitoUserSession = await Auth.currentSession();
            const idTokenExpire: number = session.getIdToken().getExpiration();
            const refreshToken: CognitoRefreshToken = session.getRefreshToken();
            const currentTimeSeconds: number = Math.round(+new Date() / 1000);

            if (idTokenExpire < currentTimeSeconds) {
                const res: CognitoUser = await Auth.currentAuthenticatedUser();
                res.refreshSession(refreshToken, (err: Error, data: CognitoUserSession) => {
                    if (err) {
                        return Auth.signOut();
                    } else {
                        return data.getIdToken().getJwtToken();
                    }
                });
            } else {
                return session.getIdToken().getJwtToken();
            }
        } catch (e) {
            return null;
        }
    }
}
