<mat-button-toggle-group
    aria-label="View selection"
    id="view-selection-btn-group"
    name="maps"
    [value]="activeView"
    (change)="changeView($event)"
>
    <mat-button-toggle [value]="MapsViews.Dashboard">Dashboard</mat-button-toggle>
    <mat-button-toggle [value]="MapsViews.Plans">Plans</mat-button-toggle>
    <mat-button-toggle [value]="MapsViews.Assort">Assort</mat-button-toggle>
    <mat-button-toggle [value]="MapsViews.Reports">Reports</mat-button-toggle>
</mat-button-toggle-group>
