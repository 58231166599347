import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[lynkdPatternAutoFocus]'
})
export class AutoFocusDirective implements OnInit {
    public constructor(private readonly _el: ElementRef) {}

    public ngOnInit(): void {
        this._el.nativeElement.focus();
    }
}
