import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MapsViews } from '../../core/models/maps-views.enum';

@Component({
    selector: 'lynkd-pattern-maps',
    templateUrl: './maps.component.html',
    styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {
    public activeView: string | null;
    // eslint-disable-next-line @typescript-eslint/typedef
    public readonly MapsViews = MapsViews;

    public constructor(private readonly _route: ActivatedRoute) {}

    public ngOnInit(): void {
        this._route.params.subscribe((params: Params) => {
            this.activeView = params.view;
        });
    }
}
