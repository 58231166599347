import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import { firstValueFrom} from 'rxjs';
import { AppConfigService } from '../../core/data/app-config.service';
import { IMetricRange } from '../../core/models/metric-range.model';

@Injectable({
    providedIn: 'root'
})
export class MetricRangeService {
    private readonly apiUrl: string = this._appConfigService.apiUrl;
    private readonly baseUrl: string = '/api/productmeta/rangeminmax';
    private _cachedQueryParams: string;
    private _cachedMetricRange: Array<IMetricRange>;

    public constructor(private readonly _http: HttpClient,
                       private readonly _appConfigService: AppConfigService) {
    }

    public async getMetricRanges(
        time_period: string | Array<string>,
        time_range: string | Array<string>,
        department: Array<string>,
        sub_department: Array<string>,
        category: Array<string>,
        product_type: Array<string>,
        meta: Record<string, Array<string>>,
        pageSize: number
    ): Promise<Array<IMetricRange>> {
        const sortOrder: string = `&sort_order=`;
        let time: string;
        const pageSizeQuery: string = `&page_size=${pageSize}`;

        if (time_range.length) {
            const timeRanges: Array<string> = (time_range as string).split(' - ');
            const startDate: string = timeRanges[0];
            const endDate: string = timeRanges[1];
            time = `&time_range=start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            time = `&time_period=${time_period}`;
        }

        const departmentName: Array<string> = department ? department.map((value: string) => `department_name:'${value}'`) : [];

        const subDepartmentName: Array<string> = sub_department
            ? sub_department.map((value: string) => `sub_department_name:'${value}'`)
            : [];
        const categoryName: Array<string> = category ? category.map((value: string) => `category_name:'${value}'`) : [];
        const productTypeName: Array<string> = product_type
            ? product_type.map((value: string) => `product_type_name:'${value}'`)
            : [];
        const hierarchyFilters: string = `?hierarchy_filters=${departmentName
            .concat(subDepartmentName)
            .concat(categoryName)
            .concat(productTypeName)
            .join(',')}`;

        const metaData: Array<string> = [];
        if (meta) {
            for (const property in meta) {
                if (meta[property]) {
                    for (const value of meta[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }
        const metaFilters: string = metaData ? `&meta_filters=${metaData.join(',')}` : '';

        const queryParams: string = `${hierarchyFilters}${metaFilters}${time}${sortOrder}${pageSizeQuery}`;
        if (queryParams === this._cachedQueryParams && this._cachedMetricRange) {
            return this._cachedMetricRange;
        }
        const query: string = encodeURI(
            `${this.apiUrl}${this.baseUrl}${queryParams}`
        );
        this._cachedQueryParams = queryParams;
        this._cachedMetricRange = await firstValueFrom(this._http.get<Array<IMetricRange>>(query));
        return this._cachedMetricRange;
    }
}
