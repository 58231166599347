import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ICurrentBoard } from '../../../core/models/current-board.model';

@Component({
    selector: 'lynkd-pattern-side-menu-my-boards',
    templateUrl: './side-menu-my-boards.component.html',
    styleUrls: ['./side-menu-my-boards.component.scss']
})
export class SideMenuMyBoardsComponent implements OnInit {
    public get savedBoards$(): Observable<Array<ICurrentBoard>> {
        return this._savedBoards$;
    }
    @Input()
    public set savedBoards$(value: Observable<Array<ICurrentBoard>>) {
        this._savedBoards$ = value;
        if (!this._savedBoards$) {
            return;
        }
        this._savedBoards$.pipe(tap(() => this.savedBoardsSearch.setValue('')));
        const savedBoardsSearch$: Observable<string> = this.savedBoardsSearch.valueChanges.pipe(startWith(''));
        this.filteredSavedBoards$ = combineLatest([savedBoardsSearch$, this._savedBoards$]).pipe(
            map(([filterTerm, savedBoards]: [string, Array<ICurrentBoard>]) => this.filter(savedBoards, 'board_name', filterTerm))
        );
    }
    public filteredSavedBoards$: Observable<Array<ICurrentBoard>>;
    public savedBoardsSearch: FormControl = new FormControl();
    @Output()
    public readonly editSavedBoardClicked: EventEmitter<ICurrentBoard> = new EventEmitter<ICurrentBoard>();

    @Output()
    public readonly boardSelected: EventEmitter<{ saveBoard: boolean; boardId: string }> = new EventEmitter<{
        saveBoard: boolean;
        boardId: string;
    }>();

    @Output()
    public readonly deleteSavedBoardClicked: EventEmitter<string> = new EventEmitter<string>();

    private _savedBoards$: Observable<Array<ICurrentBoard>>;

    public ngOnInit(): void {
        if (!this._savedBoards$) {
            return;
        }
        this._savedBoards$.pipe(tap(() => this.savedBoardsSearch.setValue('')));
        const savedBoardsSearch$: Observable<string> = this.savedBoardsSearch.valueChanges.pipe(startWith(''));
        this.filteredSavedBoards$ = combineLatest([savedBoardsSearch$, this._savedBoards$]).pipe(
            map(([value, savedBoards]: [string, Array<ICurrentBoard>]) => this.filter(savedBoards, 'board_name', value))
        );
    }
    public selectBoard($event: MouseEvent, boardId: string): void {
        if (($event.target as HTMLAnchorElement).innerText === '...') {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            return;
        }
        const saveBoard: boolean = ($event.currentTarget as HTMLAnchorElement).classList.contains('board-name');
        this.boardSelected.emit({
            saveBoard,
            boardId
        });
    }

    public editSavedBoard($event: MouseEvent, board: ICurrentBoard): void {
        $event.stopImmediatePropagation();
        $event.preventDefault();

        if (!($event.currentTarget as HTMLAnchorElement).classList.contains('board-edit')) {
            return;
        }
        this.editSavedBoardClicked.emit(board);
    }

    public deleteSavedBoard($event: MouseEvent, boardId: string): void {
        $event.stopImmediatePropagation();
        $event.preventDefault();

        if (!($event.currentTarget as HTMLAnchorElement).classList.contains('board-delete')) {
            return;
        }
        this.deleteSavedBoardClicked.emit(boardId);
    }

    private filter<T>(arr: Array<T>, prop: keyof T, value: string): Array<T> {
        const filterValue: string = value.toLowerCase();
        return arr.filter((option: T) => {
            const optionElement: string = option[prop] as unknown as string;
            return JSON.stringify(optionElement).toLowerCase().includes(filterValue);
        });
    }
}
