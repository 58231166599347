import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMonthlyOptionReport } from '../../interfaces/monthly-option-report.interface';
import { IOptionsCalculateRowPayload } from '../../interfaces/options-calculate-row.interface';

@Component({
    selector: 'lynkd-pattern-options-monthly-table-bottom',
    templateUrl: './options-monthly-table-bottom.component.html',
    styleUrls: ['./options-monthly-table-bottom.component.scss']
})
export class OptionsMonthlyTableBottomComponent {
    @Input()
    public data: IMonthlyOptionReport;

    @Input()
    public timeValue: string;

    @Input()
    public loading: boolean;

    @Output()
    public readonly edit: EventEmitter<Array<IOptionsCalculateRowPayload>> = new EventEmitter();

    public editId: { [key: string]: number } | null = null;

    public startEdit(columnName: string, rowNumber: number): void {
        this.editId = { [columnName]: rowNumber };
    }

    public stopEdit(): void {
        const payload: Array<IOptionsCalculateRowPayload> = this.generateRowsCalcPayload();
        this.edit.emit(payload);
        this.editId = null;
    }

    public generateRowsCalcPayload(): Array<IOptionsCalculateRowPayload> {
        const payload: Array<IOptionsCalculateRowPayload> = [];

        // Take slice of rows excluding the total row
        for (const row of this.data.rows.slice(1)) {
            for (const [key, value] of Object.entries(row.sku_plans)) {
                if (!['Total', 'sku_plan'].includes(key))
                    payload.push({
                        sku_plan: +value,
                        product_level_value: row.product_level_value,
                        attribute_value: this.data.attribute_value,
                        time_value: this.timeValue,
                        sub_time_value: key
                    });
            }
        }

        return payload;
    }

    public checkSkuPlanTotalMismatch(skuPlan: number, total: number): boolean {
        return skuPlan !== total;
    }
}
