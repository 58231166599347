import { CommonModule } from '@angular/common';
import { Component as NgComponent, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { ButtonComponent } from '../button';
import { TextComponent } from '../text';
import { IconComponent } from '../icon';
import * as schema from './modal.schema';

@NgComponent({
    standalone: true,
    selector: 'pt-ui-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    imports: [CommonModule, ButtonComponent, TextComponent, IconComponent]
})
export class Component implements schema.Element {
    @Output() public readonly action = new EventEmitter();

    @Input() public open: schema.Element['open'];
    @Input() public title: schema.Element['title'];
    @Input() public sticky: schema.Element['sticky'];
    @Input() public size: 's' | 'm' = 'm';

    @Input() public buttons: schema.Element['buttons'];
    @Input() public tabs: schema.Element['tabs'];
    @Input() public icons: schema.Element['icons'];

    public active = 0;

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--open': this.open
        }),
        title: () => ({
            title: true,
            'title--tabs': this.tabs.labels.length > 0
        }),
        header: () => ({
            header: true,
            'header--sticky': this.sticky === 'top' || this.sticky === 'both'
        }),
        footer: () => ({
            footer: true,
            'footer--sticky': this.sticky === 'bottom' || this.sticky === 'both'
        }),
        tab: (index: number) => ({
            tab: true,
            'tab--active': this.active === index,
            'tab--count': this.tabs?.counts[index] > 0
        }),
        content: () => ({
            content: true,
            'content--small': this.size === 's'
        })
    };

    public content = {
        inner: (index: number) => this.active === index
    };

    public onSetActive(index: number): void {
        this.active = index;
    }

    public onAction(type: schema.Action['type']): void {
        this.action.emit({
            type
        });
    }

    @HostListener('document:keyup', ['$event']) public onKeyUp(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.action.emit({
                type: 'DISMISS'
            });
        }
    }
}
