import { Component, Input } from '@angular/core';
import { IProductDisplayAttribute } from '../../../core/models/product-display-attribute.model';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';

@Component({
    selector: 'lynkd-pattern-attribute-row',
    templateUrl: './attribute-row.component.html',
    styleUrls: ['./attribute-row.component.scss']
})
export class AttributeRowComponent {
    @Input()
    public attributeRows: Array<IProductAttributeRow>;
    @Input()
    public attribute: IProductDisplayAttribute;
}
