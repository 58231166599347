import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IOptionsRowCalculationResponse } from '../../../core/models/options-row-calculation-response.model';
import { IPreviousOptionsResponse } from '../../../core/models/previous-options-response.model';
import { ILYTable } from '../../../core/models/ly-table.model';
import { IOptionsCalculateTotalPayload } from '../../../core/models/options-calculate-total-payload.model';
import { IBudgetCalculateTotalResponse } from '../../../core/models/budget-calculate-total-response.model';
import { IBudgetSaveRequest } from '../../../core/models/budget-save-request.model';
import { IOptionsApproveRequest, IOptionsTimeValueSaveRequest, ITYTable } from '../../../core/models/ty-table.model';
import { IOptionsCalculateRowPayload } from '../interfaces/options-calculate-row.interface';
import { IMonthlyOptionReport } from '../interfaces/monthly-option-report.interface';

@Injectable({
    providedIn: 'root'
})
export class MapsService {
    public lyMockData: ILYTable = {
        columnGroups: [
            {
                name: 'options',
                order: 1,
                columns: ['product_level_value', 'op_sku_count', 'op_sob']
            },
            {
                name: 'units',
                order: 2,
                columns: ['units_sales', 'units_sob']
            },
            {
                name: 'value',
                order: 3,
                columns: ['value_wsp_total', 'value_sob']
            },
            {
                name: 'avg units + value',
                order: 4,
                columns: ['avg_units', 'moq', 'avg_wsp']
            }
        ],
        columns: [
            {
                name: 'product_level_value',
                label: 'Metric Value',
                order: 1,
                type: 'text'
            },
            {
                name: 'op_sku_count',
                label: 'SKU count',
                order: 2,
                type: 'decimal'
            },
            {
                name: 'op_sob',
                label: 'SOB',
                order: 3,
                type: 'percentage'
            },
            {
                name: 'units_sales',
                label: 'Units Sales',
                order: 4,
                type: 'decimal'
            },
            {
                name: 'units_sob',
                label: 'Units SOB',
                order: 5,
                type: 'percentage'
            },
            {
                name: 'value_wsp_total',
                label: 'WSP Total',
                order: 6,
                type: 'decimal'
            },
            {
                name: 'value_sob',
                label: 'Value SOB',
                order: 7,
                type: 'percentage'
            },
            {
                name: 'avg_units',
                label: 'AVG Units',
                order: 8,
                type: 'decimal'
            },
            {
                name: 'moq',
                label: 'MOQ',
                order: 9,
                type: 'decimal'
            },
            {
                name: 'avg_wsp',
                label: 'AVG WSP',
                order: 10,
                type: 'decimal'
            }
        ],
        data: [
            {
                attribute_value: 'SPORTS',
                product_level_value: 'SS T-SHIRTS',
                op_sku_count: 73,
                op_sob: 25,
                units_sales: 108487,
                units_sob: 38,
                value_wsp_total: 23145146.780248,
                value_sob: 23,
                avg_units: 1486,
                moq: 500,
                avg_wsp: 213
            },
            {
                attribute_value: 'SPORTS',
                product_level_value: 'SS T-SHIRTS',
                op_sku_count: 73,
                op_sob: 25,
                units_sales: 108487,
                units_sob: 38,
                value_wsp_total: 23145146.780248,
                value_sob: 23,
                avg_units: 1486,
                moq: 500,
                avg_wsp: 213
            },
            {
                attribute_value: 'SPORTS',
                product_level_value: 'SS T-SHIRTS',
                op_sku_count: 73,
                op_sob: 25,
                units_sales: 108487,
                units_sob: 38,
                value_wsp_total: 23145146.780248,
                value_sob: 23,
                avg_units: 1486,
                moq: 500,
                avg_wsp: 213
            }
        ],
        totals: {
            attribute_value: 'SPORTS',
            product_level_value: 'SS T-SHIRTS',
            op_sku_count: 73,
            op_sob: 25,
            units_sales: 108487,
            units_sob: 38,
            value_wsp_total: 23145146.780248,
            value_sob: 23,
            avg_units: 1486,
            moq: 500,
            avg_wsp: 213
        }
    };

    public constructor(private readonly _http: HttpClient) {}

    public async getLYMockData(): Promise<ILYTable> {
        return this.lyMockData;
    }

    public async getTYMockData(): Promise<ITYTable> {
        return {
            columnGroups: [
                {
                    name: 'options',
                    order: 1,
                    columns: ['sku_plan', 'op_sob']
                },
                {
                    name: 'units',
                    order: 2,
                    columns: ['units_sales', 'units_sob']
                },
                {
                    name: 'value',
                    order: 3,
                    columns: ['value_wsp_total', 'value_sob', 'value_budget', 'value_var']
                }
            ],
            columns: [
                {
                    name: 'sku_plan',
                    label: 'SKU plan',
                    order: 1,
                    type: 'decimal'
                },
                {
                    name: 'op_sob',
                    label: 'SOB',
                    order: 2,
                    type: 'percentage'
                },
                {
                    name: 'units_sales',
                    label: 'Units Sales',
                    order: 3,
                    type: 'decimal'
                },
                {
                    name: 'units_sob',
                    label: 'Units SOB',
                    order: 4,
                    type: 'percentage'
                },
                {
                    name: 'value_wsp_total',
                    label: 'WSP Total',
                    order: 5,
                    type: 'decimal'
                },
                {
                    name: 'value_sob',
                    label: 'Value SOB',
                    order: 6,
                    type: 'percentage'
                },
                {
                    name: 'value_budget',
                    label: 'Value Budget',
                    order: 7,
                    type: 'decimal'
                },
                {
                    name: 'value_var',
                    label: 'Value Var',
                    order: 8,
                    type: 'decimal'
                }
            ],
            data: [],
            total: {
                attribute_value: '',
                product_level_value: 'Total',
                sku_plan: 0,
                op_sob: 0,
                units_sales: 0,
                units_sob: 0,
                value_wsp_total: 0,
                value_sob: 0,
                value_budget: 0,
                value_var: 0
            }
        };
    }

    public async getAttributeValues(): Promise<Array<string>> {
        return firstValueFrom(this._http.get<Array<string>>(`${environment.apiUrl}/api/planning/GetAttributeValues`));
    }

    public async getTimeValues(): Promise<Array<string>> {
        return firstValueFrom(this._http.get<Array<string>>(`${environment.apiUrl}/api/maps/GetTimeValues`));
    }

    public calculateBudgetTotals(
        totalBudget: number,
        corePercentage: number,
        fashionPercentage: number
    ): Observable<IBudgetCalculateTotalResponse> {
        const payload: IOptionsCalculateTotalPayload = {
            total_budget: totalBudget,
            core_percentage: corePercentage,
            fashion_percentage: fashionPercentage
        };

        return this._http.post<IBudgetCalculateTotalResponse>(`${environment.apiUrl}/api/budgets/CalculateTotal`, payload);
    }

    public saveBudget(model: IBudgetSaveRequest): Observable<number> {
        return this._http.post<number>(`${environment.apiUrl}/api/budgets/Save`, model);
    }

    public getBudget(attributeValue: string, timeValue: string): Observable<IBudgetCalculateTotalResponse> {
        return this._http.get<IBudgetCalculateTotalResponse>(
            `${environment.apiUrl}/api/budgets/Get?attributeValue=${attributeValue}&timeValue=${timeValue}`
        );
    }

    public async getPreviousOptions(timeValue: string, attributeValue: string): Promise<Array<IPreviousOptionsResponse>> {
        return firstValueFrom(
            this._http.get<Array<IPreviousOptionsResponse>>(
                `${environment.apiUrl}/api/options/GetPreviousOptions?timeValue=${timeValue}&attributeValue=${attributeValue}`
            )
        );
    }

    public async getAllOptions(timeValue: string, attributeValue: string): Promise<Array<IOptionsRowCalculationResponse>> {
        return firstValueFrom(
            this._http.get<Array<IOptionsRowCalculationResponse>>(
                `${environment.apiUrl}/api/options/GetAll?timeValue=${timeValue}&attributeValue=${attributeValue}`
            )
        );
    }

    public async calculateOptionsRows(rows: Array<IOptionsTimeValueSaveRequest>): Promise<Array<IOptionsRowCalculationResponse>> {
        return firstValueFrom(
            this._http.post<Array<IOptionsRowCalculationResponse>>(`${environment.apiUrl}/api/options/CalculateRow`, rows)
        );
    }

    public async saveOptions(model: Array<IOptionsTimeValueSaveRequest>): Promise<number> {
        return firstValueFrom(this._http.post<number>(`${environment.apiUrl}/api/options/Save`, model));
    }

    public async approveOptions(model: IOptionsApproveRequest): Promise<number> {
        return firstValueFrom(this._http.post<number>(`${environment.apiUrl}/api/options/Approve`, model));
    }

    public async optionsCalculateRow(model: Array<IOptionsCalculateRowPayload>): Promise<IMonthlyOptionReport> {
        return firstValueFrom(
            this._http.post<IMonthlyOptionReport>(`${environment.apiUrl}/api/OptionsMonthly/CalculateRows`, model)
        );
    }

    public async getAllMonthlyOptions(attributeValue: string, timeValue: string): Promise<IMonthlyOptionReport> {
        return firstValueFrom(
            this._http.get<IMonthlyOptionReport>(
                `${environment.apiUrl}/api/OptionsMonthly/GetAll?attributeValue=${attributeValue}&timeValue=${timeValue}`
            )
        );
    }
}
