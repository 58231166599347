import { Component } from '@angular/core';

@Component({
    selector: 'lynkd-pattern-assortment',
    templateUrl: './assortment.component.html',
    styleUrls: ['./assortment.component.scss']
})
export class AssortmentComponent {
    public availableTimeValues: Array<{ label: string; checked: boolean }> = [
        {
            label: 'SS24',
            checked: true
        },
        {
            label: 'SS23',
            checked: false
        },
        {
            label: 'SS22',
            checked: false
        }
    ];

    public availableProductLevelValues: Array<{ label: string; open: boolean; count: number }> = [
        {
            label: 'Kids',
            open: false,
            count: 1
        },
        {
            label: 'Womens',
            open: false,
            count: 0
        },
        {
            label: 'Mens',
            open: true,
            count: 2
        }
    ];

    public productBlocks: Array<{
        phase: string;
        selected: boolean;
        name: string;
        core: string;
        season: string;
        op: string;
        po: string;
        rp: string;
    }> = [
        {
            phase: 'Phase B',
            selected: false,
            name: 'Golfer',
            core: '10 / 52 000 000',
            season: '25 / 800 000',
            op: '8',
            po: '12',
            rp: '18'
        },
        {
            phase: 'Phase C',
            selected: false,
            name: 'Sweater',
            core: '11 250 000 / 52 000 000',
            season: '250 000 / 800 000',
            op: '8',
            po: '12',
            rp: '18'
        },
        {
            phase: 'Phase A',
            selected: true,
            name: 'Hoodies',
            core: '1 250 000 / 52 000 000',
            season: '250 000 / 800 000',
            op: '8',
            po: '12',
            rp: '18'
        }
    ];
}
