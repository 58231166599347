import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';

export interface IExportAsExcelProps<T extends { image_id?: string; }> {
    readonly data: Array<T>;
    readonly fileName: string;
    readonly sheetName?: string;
    readonly header?: Array<{
        header: string;
        key: string;
    }>;
    readonly table?: HTMLElement;
}

// noinspection JSConstantReassignment
@Injectable({
    providedIn: 'root'
})
export class ExcelExportService {
    private readonly fileExtension: string = '.xlsx';

    public async parseDataWithImage<T>(url: string, data: T): Promise<T & { image: string | ArrayBuffer | null }> {
        return new Promise((resolve: (value: T & { image: string | ArrayBuffer | null }) => void) => {
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.onload = (): void => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    const reader: FileReader = new FileReader();
                    reader.onloadend = (): void => {
                        resolve({ ...data, image: reader.result });
                    };
                    reader.readAsDataURL(xhr.response);
                } else {
                    resolve({ ...data, image: null });
                }
            };
            xhr.onerror = (): void => {
                resolve({ ...data, image: null });
            };

            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    public async exportToExcel<T extends { image_id?: string, image?: string | ArrayBuffer | null }>(
        { data = [], fileName, sheetName = 'Data', header = undefined }: IExportAsExcelProps<T>,
        hasImages: boolean = false
    ): Promise<Error> {
        const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
        const worksheet: ExcelJS.Worksheet = workbook.addWorksheet(sheetName);

        if (!data.length) {
            return Error('No Data');
        }

        // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
        const { image_id, ...restCols }: T = data[0];
        const cols: Array<{
            header: string;
            key: string;
        }> =
            header ||
            Object.keys(restCols).map((col: string) => ({
                header: col.toUpperCase(),
                key: col
            }));
        worksheet.columns = hasImages ? [{ header: 'IMAGE', key: 'image' }, ...cols] : cols;
        const sliceData: Array<T> = data;
        const parseSmallImage = (img: string): string => img?.replace('/original/', '/mini/');
        // const parseSmallImage = (img: string): string => {
        //   img = img.replace('/web/', '/mini/');
        //   return img;
        // };

        const allPromise: Array<T> = hasImages
            ? await Promise.all(sliceData.map(async (sub: T) => this.parseDataWithImage(parseSmallImage(sub.image_id), sub)))
            : await Promise.all(sliceData.map((sub: T) => sub));
        // const allPromise = await Promise.all(sliceData.map(sub => sub))
        allPromise.forEach((rowData: T, index: number) => {
            const { image: base64Image, image_id: imageUrl, ...restRowData }: T = rowData;
            const row: Record<string, string> = {};
            for (const col of cols) {
                row[col.key] = restRowData[col.key];
            }
            worksheet.addRow(row, 'i');
            if (base64Image) {
                const imageExtension: string = imageUrl.split('.').pop();
                let convertedExtension: 'jpeg' | 'png' | 'gif';
                if (imageExtension ===  'jpeg' || imageExtension === 'png' || imageExtension === 'gif'){
                    convertedExtension = imageExtension;
                }else {
                    convertedExtension = 'png';
                }
                const currentImage: number = workbook.addImage({
                    base64: base64Image as string,
                    extension: convertedExtension
                });
                worksheet.addImage(currentImage, {
                    tl: { col: 0, row: index + 1 },
                    ext: { width: 25, height: 20 },
                    hyperlinks: {
                        hyperlink: imageUrl,
                        tooltip: imageUrl
                        // hyperlink: imageUrl.replace('/original/', '/mini/').replace('d5vrniv3qpnwo', 'd3jahuujzkzo9e'),
                        // tooltip: imageUrl.replace('/original/', '/mini/').replace('d5vrniv3qpnwo', 'd3jahuujzkzo9e')
                    }
                    // editAs: 'oneCell'
                });
            }
        });

        const buffer: ExcelJS.Buffer = await workbook.xlsx.writeBuffer();
        this.saveAs(buffer, `${fileName}.${this.fileExtension}`);
    }

    public saveAs(buffer: ExcelJS.Buffer, fileName: string): void {
        // saving text file
        const blob: Blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url: string = window.URL.createObjectURL(blob);
        const anchorElem: HTMLAnchorElement = document.createElement('a');
        anchorElem.style.display = 'none';
        anchorElem.href = url;
        anchorElem.download = fileName;
        document.body.appendChild(anchorElem);
        anchorElem.click();
        document.body.removeChild(anchorElem);
        // On Edge, revokeObjectURL should be called only after
        // a.click() has completed, atleast on EdgeHTML 15.15048
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 1000);
    }
}
