import { format as formatDate } from 'date-fns';
import * as schema from '../schema';

export const IDENTIFIER_WIDTH = 26;
export const IMAGE_WIDTH = 3;

/**
 * Used to calculate the cells array for the row that represents the different
 * column groups. The intent is that the column-headers themselves will be in
 * the row below, meaning that the width of the group-cells should match the
 * combined width of the columns they represent (below).
 *
 * Note that an empty cell will always be placed above the image and identifier
 * cells (since they don't belong to a group).
 *
 * See the `Cell` schema type for more information.
 */
export const calcGroupCells = (props: { columns: Record<string, schema.table.Column> }): schema.table.DisplayCell[] => {
    const { columns } = props;

    const handler = (acc: Record<string, number>, { group, width }: schema.table.Column) => {
        const current = acc[group] || 0;
        return { ...acc, [group]: current + width };
    };

    const sizeMap = Object.values(columns).reduce(handler, {});

    const inner: schema.table.DisplayCell[] = Object.entries(sizeMap).map(([content, width], index) => ({
        content,
        width,
        variant: 'header',
        selected: false,
        location: [index + 1, 0]
    }));

    const result: schema.table.DisplayCell[] = [
        {
            width: IDENTIFIER_WIDTH + IMAGE_WIDTH,
            content: '',
            variant: 'header',
            selected: false,
            location: [0, 0]
        },
        ...inner
    ];

    return result;
};

/**
 * Used to calculate the cells array for the row that represents the individual
 * column headers.
 *
 * Note that similar to `calcGroupCells`, the actual name of the cells won't be
 * placed above the identifier and the image, since these are self-explanatory.
 * Instead text will be shown in the cells that covers both that indicates how
 * many items are displayed in the table instead.
 *
 * See the `Cell` schema type for more information.
 */
export const calcColumnCells = (props: {
    columns: Record<string, schema.table.Column>;
    total: number;
}): schema.table.DisplayCell[] => {
    const { columns, total } = props;

    const inner: schema.table.DisplayCell[] = Object.values(columns).map(({ label, width }, index) => ({
        content: label,
        width,
        variant: 'header',
        selected: false,
        location: [index + 1, 1]
    }));

    const result: schema.table.DisplayCell[] = [
        {
            width: IDENTIFIER_WIDTH + IMAGE_WIDTH,
            content: `${total} items`,
            variant: 'header',
            selected: false,
            location: [0, 1]
        },
        ...inner
    ];

    return result;
};

/**
 * Accepts a specific unique identifier for a row, and then uses the `rows`
 * object to find the matching row. This row is then mapped over to create an
 * array of cell objects, where each cell object represents a specific value in
 * the row. The width of the cells in the array are inferred from the matching
 * index in the `columns` object.
 *
 * This function takes into account the ways in which different types of values
 * should be rendered in the table.
 *
 *
 *UUUUUUUUUUPDATE
 */
export const calcRowCells = (
    props: Pick<schema.interfaces.TableContent, 'columns' | 'rows' | 'selection' | 'loading'> & {
        key: string;
    }
): schema.table.DisplayCell[] => {
    const { columns, key, rows, loading, selection = [0, 0] } = props;

    if (loading) {
        const placeholders = Object.values(columns);

        return placeholders.map(
            ({ width }): schema.table.DisplayCell => ({
                content: '',
                width,
                selected: false,
                variant: 'placeholder',
                location: [0, 0]
            })
        );
    }

    const [x, y] = selection || [0, 0];
    const { image, content } = rows[key];
    const index = Object.keys(rows).indexOf(key);
    if (index === -1) throw new Error('Key not found in rows');

    const inner: schema.table.DisplayCell[] = content.map((value, innerIndex) => {
        const { width, type: variant } = Object.values(columns)[innerIndex];
        const cellX = innerIndex + 2;
        const cellY = index + 2;
        const location: [number, number] = [cellX, cellY];

        const selected = x === cellX && y === cellY;

        if (!value) {
            return {
                content: '',
                width,
                selected,
                variant,
                location
            };
        }

        if (variant === 'day') {
            return {
                content: formatDate(new Date(value), 'yyyy-MM-dd'),
                width,
                selected,
                variant,
                location
            };
        }

        return {
            content: value,
            width,
            selected,
            variant,
            location
        };
    });

    const result: schema.table.DisplayCell[] = [
        {
            width: IDENTIFIER_WIDTH,
            content: key,
            variant: 'identifier',
            selected: false,
            location: [0, index + 2]
        },
        {
            width: IMAGE_WIDTH,
            content: image,
            variant: 'image',
            selected: x === 1 && y === index + 2,
            location: [1, index + 2]
        },
        ...inner
    ];

    return result;
};

/**
 * Determines the total width of the entire table, based on the individual
 * widths of all columns provided. Note that a user should be able to scroll
 * horizontally if the size of the columns exceeds the width of their device's
 * screen.
 */
export const calcTotalWidth = (columns: Record<string, schema.table.Column>): number => {
    const result = Object.values(columns).reduce((acc, { width }) => acc + width, IDENTIFIER_WIDTH + IMAGE_WIDTH);
    return result;
};

/**
 *
 */
const calcContent = (
    props: Pick<schema.interfaces.TableContent, 'columns' | 'rows' | 'selection' | 'loading'>
): schema.table.Section[] => {
    const { columns, loading, rows, selection } = props;

    if (loading) {
        const array = new Array(30).fill(0).map((_, index) => index.toString());

        return array.map(key => {
            const inner = calcRowCells({ columns, rows: {}, key, selection: null, loading });

            return {
                type: 'content',
                content: inner
            };
        });
    }

    return Object.keys(rows).map(key => {
        const inner = calcRowCells({ columns, rows, key, selection, loading });

        return {
            type: 'content',
            content: inner
        };
    });
};

/**
 * Note that in the interest of performance and screen real estate, both header
 * rows are combined into a single virtualized row. This means that while there
 * is a 1-to-1 match for content rows to the virtualization, both headers are
 * stacked on top of one another in the same row.
 *
 * The `type` property is used to distinguish between the header virtual row and
 * the rest of the rows (when mapping over rows for virtualization)
 */
export const createVirtualRows = (
    props: Pick<schema.interfaces.TableContent, 'columns' | 'rows' | 'selection' | 'loading'>
): schema.table.Section[] => {
    const { columns, rows, selection, loading } = props;
    const rowKeys = Object.keys(rows);
    const total = rowKeys.length;

    const groups: schema.table.DisplayCell[] = calcGroupCells({ columns });
    const labels: schema.table.DisplayCell[] = calcColumnCells({ columns, total });

    return [
        {
            type: 'header',
            groups,
            columns: labels
        },
        ...calcContent({ columns, rows, selection, loading })
    ];
};

/**
 * While all cell items in the table share the same basic styling, there are
 * noticeable exceptions between different variations of cells. The following
 * takes a single `Cell` object and calculates the CSS modifiers that should be
 * applied in a BEM fashion.
 *
 * More details on BEM can be found at
 * https://en.bem.info/methodology/quick-start/
 */
export const calcItemModifiers = (props: {
    cell: schema.table.DisplayCell;
    index: number;
}): Record<schema.table.Modifier, boolean> => {
    const { cell, index } = props;
    const { selected, variant } = cell;

    if (variant === 'placeholder') {
        return {
            selectable: false,
            selected: false,
            header: false,
            identifier: false,
            image: false,
            zebra: false
        };
    }

    const zebra = index % 2 === 0;

    if (variant === 'identifier') {
        return {
            selectable: true,
            selected: false,
            header: false,
            identifier: true,
            image: false,
            zebra
        };
    }

    if (variant === 'header') {
        return {
            selectable: false,
            selected: false,
            header: true,
            identifier: false,
            image: false,
            zebra
        };
    }

    return {
        selectable: true,
        selected,
        header: false,
        identifier: false,
        image: variant === 'image',
        zebra
    };
};

/**
 * Takes an object created by `calcItemModifiers` and converts it into a string
 * of BEM classes that should be passed to an item in the table directly.
 *
 * See `calcItemModifiers` for more details.
 */
export const calcItemClasses = (props: { cell: schema.table.DisplayCell; index: number }): string => {
    const { cell, index } = props;
    const modifiers = calcItemModifiers({ cell, index });

    const inner = Object.entries(modifiers)
        .filter(([, value]) => value)
        .map(([key]) => `cell--${key}`);

    return ['cell', ...inner].join(' ');
};
