import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ListingModule } from '../listing/listing.module';

import { SharedModule } from '../../shared/shared.module';
import { MapsModule } from '../maps/maps.module';

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        CommonModule, 
        SharedModule, 
        DashboardRoutingModule, 
        ListingModule,
        MapsModule
    ]
})
export class DashboardModule {}
