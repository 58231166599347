import { IProductDisplayAttribute } from './product-display-attribute.model';

export const defaultDisplayAttributes: Array<IProductDisplayAttribute> = [
    // {
    //     column_name: 'category_name'
    // },
    {
        column_name: 'product_type_name',
        attribute_type: 'product_level'
    },
    {
        column_name: 'attr_colour_name',
        attribute_type: 'attribute'
    },
    {
        column_name: 'attr_supplier_name',
        attribute_type: 'attribute'
    }
];
