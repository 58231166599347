import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExcelExportService } from '../../../core/data/excel-export.service';
import { IBoard } from '../../../core/models/board.model';
import { ICurrentBoard } from '../../../core/models/current-board.model';
import { IDisplayMetric } from '../../../core/models/display-metric.model';
import { IFilterData } from '../../../core/models/filter-data.model';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';
import { IProduct } from '../../../core/models/product.model';
import {
    actionStoreApplyFilters,
    actionStoreSetAttributeDisplayMetrics,
    actionStoreSetAttributeTableSort
} from '../../../core/store/store.actions';
import { IState } from '../../../core/store/store.model';
import {
    selectAttributeDisplayMetrics,
    selectAttributeTableSort,
    selectBoardData,
    selectFilterData
} from '../../../core/store/store.selectors';
import { defaultDisplayAttributes } from '../../../core/models/default-display-attributes.const';
import { IProductDisplayAttributeMetric } from '../../../core/models/product-display-attribute-metric.model';
import { AttributeService } from '../../../shared/services/attribute.service';

@Component({
    selector: 'lynkd-pattern-attribute-table',
    templateUrl: './attribute-table.component.html',
    styleUrls: ['./attribute-table.component.scss']
})
export class AttributeTableComponent implements OnInit, AfterViewInit, OnDestroy {
    public get columns(): Array<IProductDisplayAttributeMetric> {
        if (!this._columns) {
            this._columns = [];
        }
        return this._columns;
    }

    public set columns(value: Array<IProductDisplayAttributeMetric>) {
        this._columns = value;
        // this.defaultProductDisplayAttributeMetrics$ = this._attributeService.getDefaultProductDisplayAttributeMetrics();
        // if (!value) {
        //     this.defaultProductDisplayAttributeMetrics$.subscribe((data: Array<IProductDisplayAttributeMetric>) => {
        //         this._columns = data;
        //     });
        //     return;
        // }
        // this.availableProductDisplayMetrics$ = this._attributeService.getAvailableProductDisplayAttributeMetrics();
        // this.availableProductDisplayMetrics$.subscribe((data: Array<IProductDisplayAttributeMetric>) => {
        //     this._columns = value.map((t: IProductDisplayAttributeMetric) =>
        //         data.find((x: IProductDisplayAttributeMetric) => x.column_name === t.column_name));
        //     // this._columns = data;
        // });
    }

    public get attributeRows(): Array<IProductAttributeRow> {
        return this._attributeRows;
    }

    @Input()
    public set attributeRows(value: Array<IProductAttributeRow>) {
        this.tableSource.sort = this.sort;
        this._attributeRows = orderBy(
            value.filter((t: IProductAttributeRow) => t.attribute_value !== 'Totals'),
            (x: IProductAttributeRow) => x.sls_u_ty,
            'desc'
        );
        while (this._attributeRows && this._attributeRows.length < this.minTableRows) {
            this._attributeRows.push({ attribute_value: '' });
        }

        const rows: Array<IProductAttributeRow> = value.filter((t: IProductAttributeRow) => t.attribute_value === 'Totals');
        rows.push(...this._attributeRows);
        this.tableSource.data = rows;
    }

    public tableSource: MatTableDataSource<IProductAttributeRow> = new MatTableDataSource();

    @ViewChild(MatSort)
    public sort: MatSort;

    @Input()
    public attribute: string;

    @Input()
    public attributeColumn: string;
    public currentBoard: ICurrentBoard = {
        user_id: '',
        board_id: 0,
        board_name: '',
        time_ranges: undefined as string,
        time_periods: [],
        time_years: [],
        time_quarters: [],
        time_months: [],
        location_companies: [],
        location_divisions: [],
        location_areas: [],
        location_regions: [],
        location_stores: [],
        fields: '',
        departments: [],
        sub_departments: [],
        categories: [],
        product_types: [],
        meta_datas: {},
        metric_ranges: [],
        display_metrics: [],
        type: '',
        exclusions: [],
        display_attributes: defaultDisplayAttributes,
        display_attribute_metrics: []
    };
    private _columns: Array<IProductDisplayAttributeMetric>;
    private filterData: IFilterData = {
        display_attributes: defaultDisplayAttributes
    };
    private readonly destroyed: Subject<void> = new Subject<void>();
    private minTableRows: number;
    private _attributeRows: Array<IProductAttributeRow> = [];
    private readonly displayNameMap: Map<string, number> = new Map([
        [Breakpoints.XSmall, 4],
        [Breakpoints.Small, 4],
        [Breakpoints.Medium, 4],
        [Breakpoints.Large, 4],
        [Breakpoints.XLarge, 7]
    ]);

    public constructor(
        breakpointObserver: BreakpointObserver,
        private readonly _excelExportService: ExcelExportService,
        private readonly _store: Store<IState>,
        private readonly _attributeService: AttributeService,
        private readonly _router: Router
    ) {
        breakpointObserver
            .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
            .pipe(takeUntil(this.destroyed))
            .subscribe((result: BreakpointState) => {
                for (const query of Object.keys(result.breakpoints)) {
                    if (result.breakpoints[query]) {
                        this.minTableRows = this.displayNameMap.get(query) ?? 4;
                    }
                }
            });
    }

    public ngOnInit(): void {
        this._store
            .select(selectBoardData)
            .pipe(takeUntil(this.destroyed))
            .subscribe((result: IBoard) => {
                this.currentBoard.board_id = result.board_id;
                this.currentBoard.board_name = result.board_name;
                this.currentBoard.type = result.user_id;
            });
        this._store
            .select(selectFilterData)
            .pipe(takeUntil(this.destroyed))
            .subscribe((result: IFilterData) => {
                const fields: Array<string> = ['image_id', 'style_id', 'description'];
                this.filterData = JSON.parse(JSON.stringify(result));
                this.filterData.sort_order = this.filterData.sort_order.length ? this.filterData.sort_order : 'sls_u:desc';
                this.filterData.time_period =
                    this.filterData.time_period.length ||
                    this.filterData.time_year.length ||
                    this.filterData.time_quarter.length ||
                    this.filterData.time_month.length
                        ? this.filterData.time_period
                        : '';
                this.currentBoard.time_periods = this.filterData.time_period;
                this.currentBoard.time_ranges = this.filterData.time_range;
                this.currentBoard.time_years = this.filterData.time_year;
                this.currentBoard.time_quarters = this.filterData.time_quarter;
                this.currentBoard.time_months = this.filterData.time_month;
                this.currentBoard.location_companies = this.filterData.location_company;
                this.currentBoard.location_divisions = this.filterData.location_division;
                this.currentBoard.location_areas = this.filterData.location_area;
                this.currentBoard.location_regions = this.filterData.location_region;
                this.currentBoard.location_stores = this.filterData.location_store;
                this.currentBoard.departments = this.filterData.department;
                this.currentBoard.sub_departments = this.filterData.sub_department;
                this.currentBoard.categories = this.filterData.category;
                this.currentBoard.product_types = this.filterData.product_type;
                this.currentBoard.meta_datas = this.filterData.meta_data;
                this.currentBoard.metric_ranges = this.filterData.metric_range;
                this.currentBoard.display_attributes = this.filterData.display_attributes;
                this.currentBoard.exclusions = this.filterData.exclusions as Array<IProduct>;
                this.currentBoard.fields = this.filterData.display_metrics
                    ? fields
                          .concat(this.filterData.display_metrics.map((prop: IDisplayMetric) => prop.metric_name))
                          .filter((value: string, index: number, self: Array<string>) => self.indexOf(value) === index)
                          .join(',')
                    : '';
            });

        this._store
            .select(selectAttributeDisplayMetrics)
            .pipe(takeUntil(this.destroyed))
            .subscribe(async ({ display_metrics }: { display_metrics: Array<IProductDisplayAttributeMetric> }) => {
                if (!display_metrics) {
                    const result: Array<IProductDisplayAttributeMetric> =
                        await this._attributeService.getDefaultProductDisplayAttributeMetrics();
                    this._store.dispatch(
                        actionStoreSetAttributeDisplayMetrics({
                            displayMetrics: result
                        })
                    );
                    return;
                }
                const allMetrics: Array<IProductDisplayAttributeMetric> =
                    await this._attributeService.getAvailableProductDisplayAttributeMetrics();
                this._columns = display_metrics.map((t: IProductDisplayAttributeMetric) =>
                    allMetrics.find((x: IProductDisplayAttributeMetric) => x.column_name === t.column_name)
                );
            });

        this._store
            .select(selectAttributeTableSort)
            .pipe(takeUntil(this.destroyed))
            .subscribe((tableSort: { sortOrder?: string; sortDirection?: 'asc' | 'desc' }) => {
                this.sortTable(tableSort.sortOrder, tableSort.sortDirection);
            });
    }

    public ngAfterViewInit(): void {
        this.tableSource.sort = this.sort;
    }

    public ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    public getRowColumns(): Array<string> {
        return ['attribute_value', ...this.columns.map((t: IProductDisplayAttributeMetric) => t.column_name)];
    }

    // public getTotal(name: string): number {
    //     return this._attributeRows
    //         .map((t: IProductAttributeRow) => t[name] as number)
    //         .reduce((total: number, value: number) => total + value, 0);
    //}
    public sortData(sortState: Sort): void {
        const direction: 'asc' | 'desc' = sortState.direction === '' ? 'desc' : sortState.direction;
        this._store.dispatch(actionStoreSetAttributeTableSort({ sortOrder: sortState.active, sortDirection: direction }));
        this.sortTable(sortState.active, direction);
    }

    public sortTable(sortOrder: string, sortDirection: 'asc' | 'desc'): void {
        let valueRows: Array<IProductAttributeRow> = this.tableSource.data.filter(
            (t: IProductAttributeRow) => t.attribute_value !== 'Totals' && t.attribute_value !== ''
        );
        valueRows = orderBy(valueRows, (x: IProductAttributeRow) => x[sortOrder], sortDirection);
        while (valueRows && valueRows.length < this.minTableRows) {
            valueRows.push({ attribute_value: '' });
        }

        const rows: Array<IProductAttributeRow> = this.tableSource.data.filter(
            (t: IProductAttributeRow) => t.attribute_value === 'Totals'
        );
        rows.push(...valueRows);
        this.tableSource = new MatTableDataSource<IProductAttributeRow>(rows);
        // this.tableSource.sortData(this.tableSource.data, this.tableSource.sort);
    }

    public async exportExcel(): Promise<void> {
        await this._excelExportService.exportToExcel({
            data: this.tableSource.data.slice().map((item: IProductAttributeRow) => {
                const { ...newItem }: IProductAttributeRow & { image_id?: string; image?: string | ArrayBuffer | null } = {
                    ...item
                };
                return newItem;
            }),
            fileName: `Product Attributes`
        });
    }

    public async valueClicked(value: string): Promise<boolean> {
        if (value === 'Totals') {
            return;
        }
        const appliedFilters: {
            filterData: IFilterData;
            boardData: IBoard;
        } = {
            boardData: {
                user_id: this.currentBoard.user_id,
                board_id: this.currentBoard.board_id,
                board_name: this.currentBoard.board_name
            },
            filterData: {
                time_period: this.filterData.time_period,
                time_range: this.filterData.time_range,
                time_year: this.filterData.time_year,
                time_quarter: this.filterData.time_quarter,
                time_month: this.filterData.time_month,
                location_company: this.filterData.location_company,
                location_division: this.filterData.location_division,
                location_area: this.filterData.location_area,
                location_region: this.filterData.location_region,
                location_store: this.filterData.location_store,
                sort_order: this.filterData.sort_order,
                display_metrics: this.filterData.display_metrics,
                department: this.filterData.department,
                sub_department: this.filterData.sub_department,
                category: this.filterData.category,
                product_type: this.filterData.product_type,
                meta_data: this.filterData.meta_data,
                metric_range: this.filterData.metric_range,
                exclusions: this.currentBoard.exclusions,
                display_attributes: this.filterData.display_attributes
            }
        };
        if (this.attributeColumn === 'category_name') {
            appliedFilters.filterData.category = [value];
        } else if (this.attributeColumn === 'sub_department_name') {
            appliedFilters.filterData.sub_department = [value];
        } else if (this.attributeColumn === 'department_name') {
            appliedFilters.filterData.department = [value];
        } else if (this.attributeColumn === 'product_type_name') {
            appliedFilters.filterData.product_type = [value];
        } else {
            appliedFilters.filterData.meta_data[this.attributeColumn] = [value];
        }
        this._store.dispatch(actionStoreApplyFilters(appliedFilters));

        return this._router.navigate(['/analytics', 'gallery']);
    }

    public drop(event: CdkDragDrop<IProductAttributeRow, IProductAttributeRow>): void {
        moveItemInArray(this._columns, event.previousIndex, event.currentIndex);
        this._store.dispatch(actionStoreSetAttributeDisplayMetrics({ displayMetrics: this._columns }));
    }
}
