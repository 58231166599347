import { Component, Input, ViewChild } from '@angular/core';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'lynkd-pattern-attribute-line-graph',
    templateUrl: './attribute-line-graph.component.html',
    styleUrls: ['./attribute-line-graph.component.scss']
})
export class AttributeLineGraphComponent {
    public get attributeRows(): Array<IProductAttributeRow> {
        return this._attributeRows;
    }
    @Input()
    public set attributeRows(value: Array<IProductAttributeRow>) {
        this._attributeRows = value;
        this.mapDatasets();
    }
    public get attribute(): string {
        return this._attribute;
    }
    @Input()
    public set attribute(value: string) {
        this._attribute = value;
    }
    @ViewChild(BaseChartDirective)
    public chart?: BaseChartDirective;
    public lineColours: Array<string> = ['#3a668c', '#90adc6', '#333652', '#5c646b', '#4a6f73'];

    public chartData: ChartConfiguration<'line'>['data'] = {
        labels: ['PPLY', 'PLY', 'LY', 'TY'],
        datasets: [
            {
                label: 'Black',
                backgroundColor: this.lineColours[0],
                borderColor: this.lineColours[4],
                tension: 0,
                data: [0, 0, 0, 0],
                fill: false,
                type: 'line'
            },
            {
                label: 'White',
                backgroundColor: this.lineColours[1],
                borderColor: this.lineColours[4],
                tension: 0,
                data: [0, 0, 0, 0],
                fill: false,
                type: 'line'
            },
            {
                label: 'Navy',
                backgroundColor: this.lineColours[2],
                borderColor: this.lineColours[4],
                tension: 0,
                data: [0, 0, 0, 0],
                fill: false,
                type: 'line'
            }
        ]
    };

    public chartOptions: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true
                }
            }
        },
        hover: {
            mode: 'nearest',
            intersect: true
        }
    };
    public chartLegend: boolean = true;

    private _attributeRows: Array<IProductAttributeRow> = [];
    private _attribute: string;

    private mapDatasets(): void {
        if (!this._attributeRows) {
            return;
        }
        this.chartData.datasets = this._attributeRows.map((t: IProductAttributeRow, index: number) => {
            const lineColour: string = this.lineColours[index];
            return {
                label: t.attribute_value.substring(0, 17).padEnd(19, ' '),
                backgroundColor: lineColour,
                borderColor: lineColour,
                pointBackgroundColor: lineColour,
                pointBorderColor: lineColour,
                pointHoverBackgroundColor: lineColour,
                pointHoverBorderColor: lineColour,
                tension: 0,
                data: [Math.max(t.sls_u_pply, 0), Math.max(t.sls_u_ply, 0), Math.max(t.sls_u_ly, 0), Math.max(t.sls_u_ty, 0)],
                fill: false,
                type: 'line'
            };
        });
        this.chart?.update();
    }
}
