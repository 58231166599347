<div [ngClass]="styling.wrapper()" *ngIf="options.length < 1">
    <pt-ui-text *ngIf="options.length > 0" class="overlay" size="s" [compact]="true" format="bold">Change level</pt-ui-text>
    <div class="select">{{ label }}</div>
</div>

<pt-ui-selection
    *ngIf="options.length > 0"
    [options]="content.options()"
    [value]="label"
    width="full"
    (selection)="onSelection($event)"
>
    <div [ngClass]="styling.wrapper()">
        <pt-ui-text *ngIf="options.length > 0" class="overlay" size="s" [compact]="true" format="bold">Change level</pt-ui-text>
        <div class="select">{{ label }}</div>
    </div>
</pt-ui-selection>
