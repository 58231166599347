import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, Input, EventEmitter, Output, OnInit, forwardRef } from '@angular/core';
import { IconComponent } from '../icon';

const isEmpty = (value: string) => !value || value.trim() === '';

@Component({
    standalone: true,
    selector: 'pt-ui-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    imports: [CommonModule, IconComponent],
    providers: [
        {
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => InputComponent),
            provide: NG_VALUE_ACCESSOR,
            multi: true
        }
    ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
    @Input() public disabled: boolean = false;
    @Input() public label: string = '';
    @Input() public icon: string = '';
    @Input() public value: string = '';
    @Input() public debounce: number = 0;
    @Input() public items: string = '';
    @Input() public name: string = '';
    @Input() public background: 'light' | 'dark' = 'light';
    @Input() public compact: boolean = false;

    @Output() public readonly update = new EventEmitter<string>();

    public filled = !isEmpty(this.value);
    public spinning: boolean = false;

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--disabled': this.disabled,
            'wrapper--dark': this.background === 'dark',
            'wrapper--compact': this.compact
        }),
        input: () => ({
            input: true,
            'input--icon': this.icon,
            'input--dark': this.background === 'dark',
            'input--disabled': this.disabled,
            'input--compact': this.compact
        }),
        label: () => ({
            label: true,
            'label--filled': this.filled || this.items.length > 0,
            'label--icon': this.icon,
            'label--dark': this.background === 'dark',
            'label--compact': this.compact
        }),
        spinner: () => ({ spinner: true, 'spinner--compact': this.compact }),
        arrow: () => ({ arrow: true, 'arrow--compact': this.compact }),
        option: () => ({ option: true, 'option--dark': this.background === 'dark' })
    };

    private timeout: null | NodeJS.Timeout = null;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onChange = (value: string) => undefined;
    public onTouched = () => undefined;

    public writeValue(value: string): void {
        this.value = value || '';
        this.filled = !isEmpty(this.value);
    }

    public registerOnChange(fn): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public getOptions() {
        return this.items.split(',').map(item => item.trim());
    }

    public ngOnInit() {
        this.filled = !isEmpty(this.value);
    }

    public onInput(inner: string) {
        this.onChange(inner);
        this.onTouched();
        this.value = inner;

        const empty = isEmpty(inner);
        if (this.filled && empty) this.filled = false;
        if (!this.filled && !empty) this.filled = true;

        if (!this.debounce) return this.update.emit(inner);

        if (this.timeout) clearTimeout(this.timeout);
        this.spinning = true;

        this.timeout = setTimeout(() => {
            this.spinning = false;
            this.update.emit(inner);
        }, this.debounce * 1000);
    }
}
