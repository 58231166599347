import { Component, Input } from '@angular/core';
import { IMonthlyOptionReport } from '../../interfaces/monthly-option-report.interface';
import { MapsService } from '../../services/maps.service';
import { IOptionsCalculateRowPayload } from '../../interfaces/options-calculate-row.interface';

@Component({
    selector: 'lynkd-pattern-options-monthly-table',
    templateUrl: './options-monthly-table.component.html',
    styleUrls: ['./options-monthly-table.component.scss']
})
export class OptionsMonthlyTableComponent {
    @Input()
    public attributeValue: string;

    @Input()
    public timeValue: string;

    @Input()
    public data: IMonthlyOptionReport;

    public loading: boolean;

    public constructor(private readonly _mapsService: MapsService) {}

    public async calculateRows(payload: Array<IOptionsCalculateRowPayload>): Promise<void> {
        this.loading = true;

        try {
            const res: IMonthlyOptionReport = await this._mapsService.optionsCalculateRow(payload);
            this.data = res;
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }
}
