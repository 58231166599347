import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'pt-ui-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    imports: [CommonModule]
})
export class ListComponent {
    @Input() public height: string = 'auto';

    public styling = {
        height: () => ({
            maxHeight: this.height
        })
    };
}
