import * as schema from '../schema';

/**
 *
 */
export const INITIAL_OVERLAY: schema.edit.Overlay = {
    value: '',
    coordinates: [0, 0],
    open: false,
    type: 'text',
    options: [],
    cell: [0, 0]
};

/**
 *
 */
export const calcClampX = (props: { value: number; max: number }) => {
    const { max, value } = props;
    const offsetPx = 23 * 16;
    return value + offsetPx > max ? max - offsetPx : value;
};

/**
 *
 */
export const calcClampY = (props: { value: number; options: number; max: number }) => {
    const { max, options, value } = props;

    const fixedArea = 4.5 * 16;
    const buffer = 2 * 16;
    const singleOptionPx = 16 * 3;
    const optionsPx = options * singleOptionPx;
    const maxOptionsPx = singleOptionPx * 8;

    const optionsHeight = optionsPx > maxOptionsPx ? maxOptionsPx : optionsPx;
    const requiredSpace = fixedArea * 2 + optionsHeight + buffer;

    return value + requiredSpace > max ? max - requiredSpace : value;
};

/**
 *
 */
export const calcCoordinate = (props: {
    max: [number, number];
    clicked: [number, number];
    options: number;
    open: boolean;
}): [number, number] => {
    const { max, options, open, clicked } = props;

    const [clickedX, clickedY] = clicked;
    const [maxX, maxY] = max;

    const hidePx = -20 * 16;
    if (!open) return [hidePx, hidePx];

    const x = calcClampX({ value: clickedX, max: maxX });
    const y = calcClampY({ value: clickedY, options, max: maxY });

    return [x, y];
};

/**
 *
 */
export const filterList = (props: { value: string; options: string[] }) => {
    const { value, options } = props;
    const handler = (item: string) => item.toLowerCase().includes(value.toLowerCase());
    const result = options.filter(handler);
    return result;
};

/**
 *
 */
export const extractMatch = (props: { value: string; options: string[] }) => {
    const { value, options } = props;
    const handler = (item: string) => item.toLowerCase() === value.toLowerCase();
    const result = options.find(handler);
    return result;
};

/**
 *
 */
export const calcSubmit = (props: {
    type: schema.edit.Overlay['type'];
    value: File | string;
    options: string[];
}): { result: null | File | string; message: string } => {
    const { value, type, options } = props;

    if (type === 'image' && value) return { result: value, message: '' };
    if (type === 'image') return { result: null, message: '' };
    if (typeof value !== 'string') throw new Error('Value is required to be string');

    if (value.trim().length < 1) {
        focus();

        return {
            result: null,
            message: 'Value not allowed to be blank.'
        };
    }

    if (type === 'select') {
        const match = extractMatch({ value, options });

        if (!match) {
            focus();

            return {
                result: null,
                message: 'Please select a value from the list.'
            };
        }

        return {
            result: match,
            message: ''
        };
    }

    return { result: value.trim(), message: '' };
};

/**
 *
 */
export const calcUpdate = (props: {
    newValue: string | File;
    rows: schema.interfaces.TableContent['rows'];
    overlay: schema.edit.Overlay;
    sanitize: (file: File) => string;
}): null | {
    content: string;
    path: [string, 'image'] | [string, 'content', number];
} => {
    const { newValue, overlay, sanitize, rows } = props;
    if (!newValue) return null;

    const { type, cell } = overlay;
    const [x, y] = cell;

    if (y < 2) throw new Error('Not able to update headers');
    if (x < 1) throw new Error('Not able to update identifiers');

    const rowId = Object.keys(rows)[y - 2];

    if (type === 'image') {
        return {
            content: sanitize(newValue as File),
            path: [rowId, 'image']
        };
    }

    if (typeof newValue !== 'string') throw new Error('Value is required to be string');

    if (type === 'day') {
        return {
            content: new Date(newValue).toISOString(),
            path: [rowId, 'content', x - 2]
        };
    }

    return {
        content: newValue,
        path: [rowId, 'content', x - 2]
    };
};

/**
 *
 */
export const calcOverlay = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: Pick<schema.interfaces.TableContent, 'rows' | 'columns'> & { event: any }
): null | schema.edit.Overlay => {
    const { event, rows, columns } = props;
    const cell = event.currentTarget?.dataset?.cell;
    if (!cell) throw new Error('Invalid event');
    const [x, y] = cell.split('-').map(Number);

    if (y < 2) return null;
    if (x < 1) return null;

    const coordinates: [number, number] = [event.clientX, event.clientY];
    const rowId = Object.keys(rows)[y];

    if (x === 1) {
        return {
            cell: [1, y],
            value: rows[rowId].image || '',
            open: true,
            type: 'image',
            coordinates,
            options: []
        };
    }

    const columnId = Object.keys(columns)[x - 2];
    const { options, type } = columns[columnId];

    return {
        cell: [x, y],
        value: rows[rowId].content[x - 2],
        open: true,
        type,
        coordinates,
        options
    };
};
