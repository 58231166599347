import { Component, EventEmitter, Output } from '@angular/core';
import { IAssortmentProduct } from '../../interfaces/assortment-product.model';
import { ITimeValue } from '../../interfaces/time-value.model';
import { IProductLevel } from '../../interfaces/product-value.model';

@Component({
    selector: 'lynkd-pattern-assortment-sidebar',
    templateUrl: './assortment-sidebar.component.html',
    styleUrls: ['./assortment-sidebar.component.scss']
})
export class AssortmentSidebarComponent {
    @Output()
    public readonly sideMenuToggled: EventEmitter<void> = new EventEmitter<void>();
    
    public timeValues: Array<ITimeValue> = [ 
        {
            label: 'SS24',
            checked: true
        },
        {
            label: 'SS23',
            checked: false
        },
        {
            label: 'SS22',
            checked: false
        }
    ];

    public productLevelValues: Array<IProductLevel> = [
        {
            label: 'Kids',
            open: false,
            count: 1
        },
        {
            label: 'Womens',
            open: false,
            count: 0
        },
        {
            label: 'Mens',
            open: true,
            count: 2
        }
    ];

    public products: Array<IAssortmentProduct> = [
        {
            phase: 'Phase B',
            selected: false,
            name: 'Golfer',
            core: '10 / 52 000 000',
            season: '25 / 800 000',
            op: '8',
            po: '12',
            rp: '18'
        },
        {
            phase: 'Phase C',
            selected: false,
            name: 'Sweater',
            core: '11 250 000 / 52 000 000',
            season: '250 000 / 800 000',
            op: '8',
            po: '12',
            rp: '18'
        },
        {
            phase: 'Phase A',
            selected: true,
            name: 'Hoodies',
            core: '1 250 000 / 52 000 000',
            season: '250 000 / 800 000',
            op: '8',
            po: '12',
            rp: '18'
        }
    ];

    public sideNavOpenState: boolean = false;


    public toggleSideNav(): void {
        this.sideNavOpenState = !this.sideNavOpenState;
        this.sideMenuToggled.emit();
    }
}
