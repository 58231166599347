<button [ngClass]="styling.wrapper()" [disabled]="!selectable">
    <div *ngIf="image !== undefined" class="cell cell--image">
        <div *ngIf="image === null" class="image image--loading"></div>
        <img *ngIf="image !== null" class="image" [src]="image" alt="" />
    </div>

    <div class="cell cell--inner">
        <ng-content></ng-content>
    </div>

    <div class="cell">
        <div class="arrow">
            <pt-ui-icon *ngIf="arrow && selectable" variant="forward" importance="secondary"></pt-ui-icon>
        </div>
    </div>
</button>
