import { Component, Input } from '@angular/core';
import { GraphDisplayMode } from '../../../core/models/graph-display-mode.enum';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';
import { orderBy } from 'lodash';

@Component({
    selector: 'lynkd-pattern-attribute-graph-list',
    templateUrl: './attribute-graph-list.component.html',
    styleUrls: ['./attribute-graph-list.component.scss']
})
export class AttributeGraphListComponent {
    public get attributeRows(): Array<IProductAttributeRow> {
        return this._attributeRows;
    }

    @Input()
    public set attributeRows(value: Array<IProductAttributeRow>) {
        this._attributeRows = value.filter((t: IProductAttributeRow) => t.attribute_value !== 'Totals');
        this._attributeRows = orderBy(this._attributeRows, (t: IProductAttributeRow) => t.sls_u_ty, 'desc').slice(0, 5);
        // .sort((t: IProductAttributeRow) => t.sls_u_ty)
        // .slice(0, 5);
    }

    @Input()
    public attribute: string;
    // eslint-disable-next-line @typescript-eslint/typedef
    public GraphDisplayMode = GraphDisplayMode;
    @Input()
    public graph: GraphDisplayMode = GraphDisplayMode.Bar;
    private _attributeRows: Array<IProductAttributeRow> = [];
}
